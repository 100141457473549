var Handlebars = require("../../node_modules/.pnpm/handlebars@4.7.8_patch_hash=c5t5wnxp6mvabdrpn7wm4zt2zu/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "unread_topic";
},"3":function(container,depth0,helpers,partials,data) {
    return "private_conversation_row";
},"5":function(container,depth0,helpers,partials,data) {
    var alias1=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <span class=\"zulip-icon zulip-icon-user\"></span>\n                <a href=\""
    + alias1(container.lambda(container.strict(depth0, "pm_url", {"start":{"line":7,"column":27},"end":{"line":7,"column":33}} ), depth0))
    + "\" class=\"recent-view-table-link\">"
    + alias1(lookupProperty(helpers,"t").call(depth0 != null ? depth0 : (container.nullContext || {}),"Direct messages",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":7,"column":68},"end":{"line":7,"column":92}}}))
    + "</a>\n";
},"7":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.strict, alias2=container.lambda, alias3=container.escapeExpression;

  return "                <span class=\"stream-privacy-original-color-"
    + alias3(alias2(alias1(depth0, "stream_id", {"start":{"line":9,"column":61},"end":{"line":9,"column":70}} ), depth0))
    + " stream-privacy filter-icon\" style=\"color: "
    + alias3(alias2(alias1(depth0, "stream_color", {"start":{"line":9,"column":117},"end":{"line":9,"column":129}} ), depth0))
    + "\">\n                    "
    + ((stack1 = container.invokePartial(require("./stream_privacy.hbs"),depth0,{"name":"stream_privacy","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "                </span>\n                <a href=\""
    + alias3(alias2(alias1(depth0, "topic_url", {"start":{"line":12,"column":27},"end":{"line":12,"column":36}} ), depth0))
    + "\" class=\"recent-view-table-link\">"
    + alias3(alias2(alias1(depth0, "stream_name", {"start":{"line":12,"column":73},"end":{"line":12,"column":84}} ), depth0))
    + "</a>\n";
},"9":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <div class=\"right_part\">\n                <span class=\"pm_status_icon "
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,lookupProperty(depth0,"is_group"),{"name":"unless","hash":{},"fn":container.program(10, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":18,"column":44},"end":{"line":18,"column":87}}})) != null ? stack1 : "")
    + "\" data-tippy-placement=\"top\" data-user-ids-string=\""
    + container.escapeExpression(container.lambda(container.strict(depth0, "user_ids_string", {"start":{"line":18,"column":140},"end":{"line":18,"column":155}} ), depth0))
    + "\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"is_group"),{"name":"if","hash":{},"fn":container.program(12, data, 0),"inverse":container.program(14, data, 0),"data":data,"loc":{"start":{"line":19,"column":20},"end":{"line":25,"column":27}}})) != null ? stack1 : "")
    + "                </span>\n            </div>\n";
},"10":function(container,depth0,helpers,partials,data) {
    return "show-tooltip";
},"12":function(container,depth0,helpers,partials,data) {
    return "                    <span class=\"zulip-icon zulip-icon-triple-users\"></span>\n";
},"14":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),lookupProperty(depth0,"is_bot"),{"name":"if","hash":{},"fn":container.program(15, data, 0),"inverse":container.program(17, data, 0),"data":data,"loc":{"start":{"line":21,"column":20},"end":{"line":25,"column":20}}})) != null ? stack1 : "");
},"15":function(container,depth0,helpers,partials,data) {
    return "                    <span class=\"zulip-icon zulip-icon-bot\" aria-hidden=\"true\"></span>\n";
},"17":function(container,depth0,helpers,partials,data) {
    var alias1=container.strict, alias2=container.lambda, alias3=container.escapeExpression;

  return "                    <span class=\"zulip-icon zulip-icon-"
    + alias3(alias2(alias1(depth0, "user_circle_class", {"start":{"line":24,"column":57},"end":{"line":24,"column":74}} ), depth0))
    + " "
    + alias3(alias2(alias1(depth0, "user_circle_class", {"start":{"line":24,"column":79},"end":{"line":24,"column":96}} ), depth0))
    + " user-circle\" data-presence-indicator-user-id=\""
    + alias3(alias2(alias1(depth0, "user_ids_string", {"start":{"line":24,"column":147},"end":{"line":24,"column":162}} ), depth0))
    + "\"></span>\n                    ";
},"19":function(container,depth0,helpers,partials,data) {
    return " colspan=\"2\"";
},"21":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.strict, alias2=container.lambda, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <a href=\""
    + container.escapeExpression(alias2(alias1(depth0, "pm_url", {"start":{"line":35,"column":27},"end":{"line":35,"column":33}} ), depth0))
    + "\" class=\"recent-view-table-link "
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),lookupProperty(depth0,"is_group"),{"name":"if","hash":{},"fn":container.program(22, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":35,"column":67},"end":{"line":35,"column":110}}})) != null ? stack1 : "")
    + "\">"
    + ((stack1 = alias2(alias1(depth0, "rendered_pm_with", {"start":{"line":35,"column":115},"end":{"line":35,"column":131}} ), depth0)) != null ? stack1 : "")
    + "</a>\n";
},"22":function(container,depth0,helpers,partials,data) {
    return "recent-view-dm-group";
},"24":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.strict, alias2=container.lambda, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <a class=\"white-space-preserve-wrap recent-view-table-link "
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),lookupProperty(depth0,"is_empty_string_topic"),{"name":"if","hash":{},"fn":container.program(25, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":37,"column":75},"end":{"line":37,"column":130}}})) != null ? stack1 : "")
    + "\" href=\""
    + alias3(alias2(alias1(depth0, "topic_url", {"start":{"line":37,"column":140},"end":{"line":37,"column":149}} ), depth0))
    + "\">"
    + alias3(alias2(alias1(depth0, "topic_display_name", {"start":{"line":37,"column":155},"end":{"line":37,"column":173}} ), depth0))
    + "</a>\n";
},"25":function(container,depth0,helpers,partials,data) {
    return "empty-topic-display";
},"27":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.strict, alias2=container.lambda, alias3=container.escapeExpression, alias4=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <div class=\"recent_topic_actions\">\n                    <div class=\"recent_view_focusable\" data-col-index=\""
    + alias3(alias2(alias1(lookupProperty(depth0,"column_indexes"), "read", {"start":{"line":43,"column":74},"end":{"line":43,"column":93}} ), depth0))
    + "\">\n                        <span class=\"unread_count unread_count_pm recent-view-table-unread-count "
    + ((stack1 = lookupProperty(helpers,"unless").call(alias4,lookupProperty(depth0,"unread_count"),{"name":"unless","hash":{},"fn":container.program(28, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":44,"column":97},"end":{"line":44,"column":145}}})) != null ? stack1 : "")
    + " tippy-zulip-tooltip on_hover_topic_read\" data-user-ids-string=\""
    + alias3(alias2(alias1(depth0, "user_ids_string", {"start":{"line":44,"column":211},"end":{"line":44,"column":226}} ), depth0))
    + "\" data-tippy-content=\""
    + alias3(lookupProperty(helpers,"t").call(alias4,"Mark as read",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":44,"column":250},"end":{"line":44,"column":271}}}))
    + "\" role=\"button\" tabindex=\"0\" aria-label=\""
    + alias3(lookupProperty(helpers,"t").call(alias4,"Mark as read",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":44,"column":312},"end":{"line":44,"column":333}}}))
    + "\">"
    + alias3(alias2(alias1(depth0, "unread_count", {"start":{"line":44,"column":337},"end":{"line":44,"column":349}} ), depth0))
    + "</span>\n                    </div>\n                </div>\n                <div class=\"recent_topic_actions dummy_action_button\">\n                    <div class=\"recent_view_focusable\" data-col-index=\""
    + alias3(alias2(alias1(lookupProperty(depth0,"column_indexes"), "read", {"start":{"line":48,"column":74},"end":{"line":48,"column":93}} ), depth0))
    + "\">\n                        <i class=\"zulip-icon zulip-icon-mute on_hover_topic_unmute recipient_bar_icon\"></i>\n                    </div>\n                </div>\n";
},"28":function(container,depth0,helpers,partials,data) {
    return "unread_hidden";
},"30":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, alias3=container.strict, alias4=container.lambda, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <span class=\"unread_mention_info tippy-zulip-tooltip "
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,lookupProperty(depth0,"mention_in_unread"),{"name":"unless","hash":{},"fn":container.program(28, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":54,"column":69},"end":{"line":54,"column":122}}})) != null ? stack1 : "")
    + "\"\n                  data-tippy-content=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"You have mentions",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":55,"column":38},"end":{"line":55,"column":63}}}))
    + "\">@</span>\n                <div class=\"recent_topic_actions\">\n                    <div class=\"recent_view_focusable hidden-for-spectators\" data-col-index=\""
    + alias2(alias4(alias3(lookupProperty(depth0,"column_indexes"), "read", {"start":{"line":57,"column":96},"end":{"line":57,"column":115}} ), depth0))
    + "\">\n                        <span class=\"unread_count recent-view-table-unread-count "
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,lookupProperty(depth0,"unread_count"),{"name":"unless","hash":{},"fn":container.program(28, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":58,"column":81},"end":{"line":58,"column":129}}})) != null ? stack1 : "")
    + " tippy-zulip-tooltip on_hover_topic_read\" data-stream-id=\""
    + alias2(alias4(alias3(depth0, "stream_id", {"start":{"line":58,"column":189},"end":{"line":58,"column":198}} ), depth0))
    + "\" data-topic-name=\""
    + alias2(alias4(alias3(depth0, "topic", {"start":{"line":58,"column":221},"end":{"line":58,"column":226}} ), depth0))
    + "\" data-tippy-content=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"Mark as read",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":58,"column":250},"end":{"line":58,"column":271}}}))
    + "\" role=\"button\" tabindex=\"0\" aria-label=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"Mark as read",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":58,"column":312},"end":{"line":58,"column":333}}}))
    + "\">"
    + alias2(alias4(alias3(depth0, "unread_count", {"start":{"line":58,"column":337},"end":{"line":58,"column":349}} ), depth0))
    + "</span>\n                    </div>\n                </div>\n                <div class=\"recent_topic_actions\">\n                    <div class=\"hidden-for-spectators\">\n                        <span class=\"recent_view_focusable change_visibility_policy hidden-for-spectators\" data-stream-id=\""
    + alias2(alias4(alias3(depth0, "stream_id", {"start":{"line":63,"column":125},"end":{"line":63,"column":134}} ), depth0))
    + "\" data-topic-name=\""
    + alias2(alias4(alias3(depth0, "topic", {"start":{"line":63,"column":157},"end":{"line":63,"column":162}} ), depth0))
    + "\" data-col-index=\""
    + alias2(alias4(alias3(lookupProperty(depth0,"column_indexes"), "mute", {"start":{"line":63,"column":185},"end":{"line":63,"column":204}} ), depth0))
    + "\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(helpers,"eq").call(alias1,lookupProperty(depth0,"visibility_policy"),lookupProperty(lookupProperty(depth0,"all_visibility_policies"),"FOLLOWED"),{"name":"eq","hash":{},"data":data,"loc":{"start":{"line":64,"column":34},"end":{"line":64,"column":89}}}),{"name":"if","hash":{},"fn":container.program(31, data, 0),"inverse":container.program(33, data, 0),"data":data,"loc":{"start":{"line":64,"column":28},"end":{"line":76,"column":35}}})) != null ? stack1 : "")
    + "                        </span>\n                    </div>\n                </div>\n";
},"31":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                <i class=\"zulip-icon zulip-icon-follow recipient_bar_icon\" data-tippy-content=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"You follow this topic.",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":65,"column":111},"end":{"line":65,"column":141}}}))
    + "\"\n                                  role=\"button\" tabindex=\"0\" aria-haspopup=\"true\" aria-label=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"You follow this topic.",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":66,"column":94},"end":{"line":66,"column":125}}}))
    + "\"></i>\n";
},"33":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(helpers,"eq").call(alias1,lookupProperty(depth0,"visibility_policy"),lookupProperty(lookupProperty(depth0,"all_visibility_policies"),"UNMUTED"),{"name":"eq","hash":{},"data":data,"loc":{"start":{"line":67,"column":38},"end":{"line":67,"column":92}}}),{"name":"if","hash":{},"fn":container.program(34, data, 0),"inverse":container.program(36, data, 0),"data":data,"loc":{"start":{"line":67,"column":28},"end":{"line":76,"column":28}}})) != null ? stack1 : "");
},"34":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                <i class=\"zulip-icon zulip-icon-unmute-new recipient_bar_icon\" data-tippy-content=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"You have unmuted this topic.",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":68,"column":115},"end":{"line":68,"column":151}}}))
    + "\"\n                                  role=\"button\" tabindex=\"0\" aria-haspopup=\"true\" aria-label=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"You have unmuted this topic.",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":69,"column":94},"end":{"line":69,"column":131}}}))
    + "\"></i>\n";
},"36":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(helpers,"eq").call(alias1,lookupProperty(depth0,"visibility_policy"),lookupProperty(lookupProperty(depth0,"all_visibility_policies"),"MUTED"),{"name":"eq","hash":{},"data":data,"loc":{"start":{"line":70,"column":38},"end":{"line":70,"column":90}}}),{"name":"if","hash":{},"fn":container.program(37, data, 0),"inverse":container.program(39, data, 0),"data":data,"loc":{"start":{"line":70,"column":28},"end":{"line":76,"column":28}}})) != null ? stack1 : "");
},"37":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                <i class=\"zulip-icon zulip-icon-mute-new recipient_bar_icon\" data-tippy-content=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"You have muted this topic.",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":71,"column":113},"end":{"line":71,"column":147}}}))
    + "\"\n                                  role=\"button\" tabindex=\"0\" aria-haspopup=\"true\" aria-label=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"You have muted this topic.",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":72,"column":94},"end":{"line":72,"column":129}}}))
    + "\"></i>\n";
},"39":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                <i class=\"zulip-icon zulip-icon-inherit recipient_bar_icon\"\n                                  role=\"button\" tabindex=\"0\" aria-haspopup=\"true\" aria-label=\""
    + container.escapeExpression(lookupProperty(helpers,"t").call(depth0 != null ? depth0 : (container.nullContext || {}),"Notifications are based on your configuration for this channel.",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":75,"column":94},"end":{"line":75,"column":166}}}))
    + "\"></i>\n                            ";
},"41":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.strict, alias2=container.lambda, alias3=container.escapeExpression;

  return "            <li class=\"recent_view_participant_item tippy-zulip-tooltip\" data-tooltip-template-id=\"recent_view_participant_overflow_tooltip:"
    + alias3(alias2(alias1(depth0, "conversation_key", {"start":{"line":87,"column":142},"end":{"line":87,"column":158}} ), depth0))
    + "\">\n                <span class=\"recent_view_participant_overflow\">+"
    + alias3(alias2(alias1(depth0, "other_senders_count", {"start":{"line":88,"column":66},"end":{"line":88,"column":85}} ), depth0))
    + "</span>\n            </li>\n            <template id=\"recent_view_participant_overflow_tooltip:"
    + alias3(alias2(alias1(depth0, "conversation_key", {"start":{"line":90,"column":69},"end":{"line":90,"column":85}} ), depth0))
    + "\">"
    + ((stack1 = alias2(alias1(depth0, "other_sender_names_html", {"start":{"line":90,"column":92},"end":{"line":90,"column":115}} ), depth0)) != null ? stack1 : "")
    + "</template>\n";
},"43":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),lookupProperty(depth0,"is_muted"),{"name":"if","hash":{},"fn":container.program(44, data, 0),"inverse":container.program(46, data, 0),"data":data,"loc":{"start":{"line":93,"column":16},"end":{"line":101,"column":23}}})) != null ? stack1 : "");
},"44":function(container,depth0,helpers,partials,data) {
    var alias1=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <li class=\"recent_view_participant_item participant_profile tippy-zulip-tooltip\" data-tippy-content=\""
    + alias1(lookupProperty(helpers,"t").call(depth0 != null ? depth0 : (container.nullContext || {}),"Muted user",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":94,"column":117},"end":{"line":94,"column":135}}}))
    + "\" data-user-id=\""
    + alias1(container.lambda(container.strict(depth0, "user_id", {"start":{"line":94,"column":153},"end":{"line":94,"column":165}} ), depth0))
    + "\">\n                    <span><i class=\"fa fa-user recent_view_participant_overflow\"></i></span>\n                </li>\n";
},"46":function(container,depth0,helpers,partials,data) {
    var alias1=container.strict, alias2=container.lambda, alias3=container.escapeExpression;

  return "                <li class=\"recent_view_participant_item participant_profile tippy-zulip-tooltip\" data-tippy-content=\""
    + alias3(alias2(alias1(depth0, "full_name", {"start":{"line":98,"column":119},"end":{"line":98,"column":133}} ), depth0))
    + "\" data-user-id=\""
    + alias3(alias2(alias1(depth0, "user_id", {"start":{"line":98,"column":153},"end":{"line":98,"column":165}} ), depth0))
    + "\">\n                    <img src=\""
    + alias3(alias2(alias1(depth0, "avatar_url_small", {"start":{"line":99,"column":32},"end":{"line":99,"column":53}} ), depth0))
    + "\" class=\"recent_view_participant_avatar\" />\n                </li>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.strict, alias2=container.lambda, alias3=container.escapeExpression, alias4=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<tr id=\"recent_conversation:"
    + alias3(alias2(alias1(depth0, "conversation_key", {"start":{"line":1,"column":30},"end":{"line":1,"column":46}} ), depth0))
    + "\" class=\""
    + ((stack1 = lookupProperty(helpers,"if").call(alias4,lookupProperty(depth0,"unread_count"),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":57},"end":{"line":1,"column":96}}})) != null ? stack1 : "")
    + " "
    + ((stack1 = lookupProperty(helpers,"if").call(alias4,lookupProperty(depth0,"is_private"),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":97},"end":{"line":1,"column":146}}})) != null ? stack1 : "")
    + "\">\n    <td class=\"recent_topic_stream\">\n        <div class=\"flex_container flex_container_pm\">\n            <div class=\"left_part recent_view_focusable\" data-col-index=\""
    + alias3(alias2(alias1(lookupProperty(depth0,"column_indexes"), "stream", {"start":{"line":4,"column":76},"end":{"line":4,"column":97}} ), depth0))
    + "\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias4,lookupProperty(depth0,"is_private"),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.program(7, data, 0),"data":data,"loc":{"start":{"line":5,"column":16},"end":{"line":13,"column":23}}})) != null ? stack1 : "")
    + "            </div>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias4,lookupProperty(depth0,"is_private"),{"name":"if","hash":{},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":16,"column":12},"end":{"line":28,"column":19}}})) != null ? stack1 : "")
    + "        </div>\n    </td>\n    <td class=\"recent_topic_name\""
    + ((stack1 = lookupProperty(helpers,"if").call(alias4,lookupProperty(helpers,"not").call(alias4,lookupProperty(depth0,"is_spectator"),{"name":"not","hash":{},"data":data,"loc":{"start":{"line":31,"column":39},"end":{"line":31,"column":57}}}),{"name":"if","hash":{},"fn":container.program(19, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":31,"column":33},"end":{"line":31,"column":79}}})) != null ? stack1 : "")
    + ">\n        <div class=\"flex_container\">\n            <div class=\"left_part recent_view_focusable line_clamp\" data-col-index=\""
    + alias3(alias2(alias1(lookupProperty(depth0,"column_indexes"), "topic", {"start":{"line":33,"column":87},"end":{"line":33,"column":107}} ), depth0))
    + "\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias4,lookupProperty(depth0,"is_private"),{"name":"if","hash":{},"fn":container.program(21, data, 0),"inverse":container.program(24, data, 0),"data":data,"loc":{"start":{"line":34,"column":16},"end":{"line":38,"column":23}}})) != null ? stack1 : "")
    + "            </div>\n            <div class=\"right_part\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias4,lookupProperty(depth0,"is_private"),{"name":"if","hash":{},"fn":container.program(27, data, 0),"inverse":container.program(30, data, 0),"data":data,"loc":{"start":{"line":41,"column":16},"end":{"line":80,"column":23}}})) != null ? stack1 : "")
    + "            </div>\n        </div>\n    </td>\n    <td class='recent_topic_users'>\n        <ul class=\"recent_view_participants\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias4,lookupProperty(depth0,"other_senders_count"),{"name":"if","hash":{},"fn":container.program(41, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":86,"column":12},"end":{"line":91,"column":19}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"each").call(alias4,lookupProperty(depth0,"senders"),{"name":"each","hash":{},"fn":container.program(43, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":92,"column":12},"end":{"line":102,"column":21}}})) != null ? stack1 : "")
    + "        </ul>\n    </td>\n    <td class=\"recent_topic_timestamp\">\n        <div class=\"last_msg_time tippy-zulip-tooltip\" data-tippy-content=\""
    + alias3(alias2(alias1(depth0, "full_last_msg_date_time", {"start":{"line":106,"column":77},"end":{"line":106,"column":105}} ), depth0))
    + "\">\n            <a href=\""
    + alias3(alias2(alias1(depth0, "last_msg_url", {"start":{"line":107,"column":23},"end":{"line":107,"column":35}} ), depth0))
    + "\" tabindex=\"-1\">"
    + alias3(alias2(alias1(depth0, "last_msg_time", {"start":{"line":107,"column":56},"end":{"line":107,"column":69}} ), depth0))
    + "</a>\n        </div>\n    </td>\n</tr>\n";
},"usePartial":true,"useData":true});