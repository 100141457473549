var Handlebars = require("../../node_modules/.pnpm/handlebars@4.7.8_patch_hash=c5t5wnxp6mvabdrpn7wm4zt2zu/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.strict, alias2=container.lambda, alias3=container.escapeExpression, alias4=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <div class=\"message_header message_header_stream\">\n            <div class=\"message-header-contents\" style=\"background: "
    + alias3(alias2(alias1(depth0, "recipient_bar_color", {"start":{"line":5,"column":70},"end":{"line":5,"column":89}} ), depth0))
    + ";\">\n                <div class=\"message_label_clickable stream_label\">\n                    <span class=\"stream-privacy-modified-color-"
    + alias3(alias2(alias1(depth0, "stream_id", {"start":{"line":7,"column":65},"end":{"line":7,"column":74}} ), depth0))
    + " stream-privacy filter-icon\"  style=\"color: "
    + alias3(alias2(alias1(depth0, "stream_privacy_icon_color", {"start":{"line":7,"column":122},"end":{"line":7,"column":147}} ), depth0))
    + "\">\n                        "
    + ((stack1 = container.invokePartial(require("./stream_privacy.hbs"),depth0,{"name":"stream_privacy","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "                    </span>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias4,lookupProperty(depth0,"stream_name"),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.program(4, data, 0),"data":data,"loc":{"start":{"line":10,"column":20},"end":{"line":14,"column":27}}})) != null ? stack1 : "")
    + "                </div>\n                <span class=\"stream_topic_separator\"><i class=\"zulip-icon zulip-icon-chevron-right\"></i></span>\n                <span class=\"stream_topic\">\n                    <div class=\"message_label_clickable narrows_by_topic\">\n                        <span class=\"stream-topic-inner "
    + ((stack1 = lookupProperty(helpers,"if").call(alias4,lookupProperty(depth0,"is_empty_string_topic"),{"name":"if","hash":{},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":19,"column":56},"end":{"line":19,"column":111}}})) != null ? stack1 : "")
    + "\">"
    + alias3(alias2(alias1(depth0, "topic_display_name", {"start":{"line":19,"column":115},"end":{"line":19,"column":133}} ), depth0))
    + "</span>\n                    </div>\n                </span>\n                <span class=\"recipient_bar_controls\"></span>\n                <div class=\"recipient_row_date\">"
    + alias3(alias2(alias1(depth0, "time_stamp", {"start":{"line":23,"column":51},"end":{"line":23,"column":61}} ), depth0))
    + "</div>\n            </div>\n        </div>\n";
},"2":function(container,depth0,helpers,partials,data) {
    return "                        "
    + container.escapeExpression(container.lambda(container.strict(depth0, "stream_name", {"start":{"line":11,"column":26},"end":{"line":11,"column":37}} ), depth0))
    + "\n";
},"4":function(container,depth0,helpers,partials,data) {
    return "                        &nbsp;\n";
},"6":function(container,depth0,helpers,partials,data) {
    return "empty-topic-display";
},"8":function(container,depth0,helpers,partials,data) {
    var alias1=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <div class=\"message_header message_header_private_message\">\n            <div class=\"message-header-contents\">\n                <div class=\"message_label_clickable stream_label\">\n                    <span class=\"private_message_header_icon\"><i class=\"zulip-icon zulip-icon-user\"></i></span>\n                    <span class=\"private_message_header_name\">"
    + alias1(lookupProperty(helpers,"t").call(depth0 != null ? depth0 : (container.nullContext || {}),"You and {recipients}",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":31,"column":62},"end":{"line":31,"column":91}}}))
    + "</span>\n                </div>\n                <div class=\"recipient_row_date\">"
    + alias1(container.lambda(container.strict(depth0, "time_stamp", {"start":{"line":33,"column":51},"end":{"line":33,"column":61}} ), depth0))
    + "</div>\n            </div>\n        </div>\n";
},"10":function(container,depth0,helpers,partials,data) {
    return " private-message";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.escapeExpression, alias2=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"draft-message-row overlay-message-row\" data-draft-id=\""
    + alias1(container.lambda(container.strict(depth0, "draft_id", {"start":{"line":1,"column":68},"end":{"line":1,"column":76}} ), depth0))
    + "\">\n    <div class=\"draft-message-info-box overlay-message-info-box\" tabindex=\"0\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias2,lookupProperty(depth0,"is_stream"),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(8, data, 0),"data":data,"loc":{"start":{"line":3,"column":8},"end":{"line":36,"column":15}}})) != null ? stack1 : "")
    + "        <div class=\"message_row"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias2,lookupProperty(depth0,"is_stream"),{"name":"unless","hash":{},"fn":container.program(10, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":37,"column":31},"end":{"line":37,"column":79}}})) != null ? stack1 : "")
    + "\" role=\"listitem\">\n            <div class=\"messagebox\">\n                <div class=\"messagebox-content\">\n                    <div class=\"message_top_line\">\n                        <div class=\"overlay_message_controls\">\n                            <i class=\"fa fa-pencil fa-lg restore-overlay-message tippy-zulip-tooltip\" aria-hidden=\"true\" data-tooltip-template-id=\"restore-draft-tooltip-template\"></i>\n                            <i class=\"fa fa-trash-o fa-lg delete-overlay-message tippy-zulip-delayed-tooltip\" aria-hidden=\"true\" data-tooltip-template-id=\"delete-draft-tooltip-template\"></i>\n                            <div class=\"draft-selection-tooltip\">\n                                <i class=\"fa fa-square-o fa-lg draft-selection-checkbox\" aria-hidden=\"true\"></i>\n                            </div>\n                        </div>\n                    </div>\n                    <div class=\"message_content rendered_markdown restore-overlay-message tippy-zulip-delayed-tooltip\" data-tooltip-template-id=\"restore-draft-tooltip-template\">"
    + alias1(lookupProperty(helpers,"rendered_markdown").call(alias2,lookupProperty(depth0,"content"),{"name":"rendered_markdown","hash":{},"data":data,"loc":{"start":{"line":49,"column":177},"end":{"line":49,"column":206}}}))
    + "</div>\n                </div>\n            </div>\n        </div>\n    </div>\n</div>\n";
},"usePartial":true,"useData":true});