var Handlebars = require("../../node_modules/.pnpm/handlebars@4.7.8_patch_hash=c5t5wnxp6mvabdrpn7wm4zt2zu/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return " deactivated-pill ";
},"3":function(container,depth0,helpers,partials,data) {
    return "data-user-id=\""
    + container.escapeExpression(container.lambda(container.strict(depth0, "user_id", {"start":{"line":1,"column":93},"end":{"line":1,"column":100}} ), depth0))
    + "\"";
},"5":function(container,depth0,helpers,partials,data) {
    return "data-user-group-id=\""
    + container.escapeExpression(container.lambda(container.strict(depth0, "group_id", {"start":{"line":1,"column":148},"end":{"line":1,"column":156}} ), depth0))
    + "\"";
},"7":function(container,depth0,helpers,partials,data) {
    return "data-stream-id=\""
    + container.escapeExpression(container.lambda(container.strict(depth0, "stream_id", {"start":{"line":1,"column":201},"end":{"line":1,"column":210}} ), depth0))
    + "\"";
},"9":function(container,depth0,helpers,partials,data) {
    return "    <img class=\"pill-image\" src=\""
    + container.escapeExpression(container.lambda(container.strict(depth0, "img_src", {"start":{"line":3,"column":35},"end":{"line":3,"column":42}} ), depth0))
    + "\" />\n";
},"11":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),lookupProperty(lookupProperty(depth0,"stream"),"invite_only"),{"name":"if","hash":{},"fn":container.program(12, data, 0),"inverse":container.program(14, data, 0),"data":data,"loc":{"start":{"line":8,"column":16},"end":{"line":14,"column":25}}})) != null ? stack1 : "");
},"12":function(container,depth0,helpers,partials,data) {
    return "<i class=\"zulip-icon zulip-icon-lock stream-privacy-type-icon\" aria-hidden=\"true\"></i>";
},"14":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),lookupProperty(lookupProperty(depth0,"stream"),"is_web_public"),{"name":"if","hash":{},"fn":container.program(15, data, 0),"inverse":container.program(17, data, 0),"data":data,"loc":{"start":{"line":10,"column":16},"end":{"line":14,"column":16}}})) != null ? stack1 : "");
},"15":function(container,depth0,helpers,partials,data) {
    return "<i class=\"zulip-icon zulip-icon-globe stream-privacy-type-icon\" aria-hidden=\"true\"></i>";
},"17":function(container,depth0,helpers,partials,data) {
    return "<i class=\"zulip-icon zulip-icon-hashtag stream-privacy-type-icon\" aria-hidden=\"true\"></i>";
},"19":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "&nbsp;<i>("
    + container.escapeExpression(lookupProperty(helpers,"t").call(depth0 != null ? depth0 : (container.nullContext || {}),"guest",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":18,"column":58},"end":{"line":18,"column":71}}}))
    + ")</i>";
},"21":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "&nbsp;("
    + container.escapeExpression(lookupProperty(helpers,"t").call(depth0 != null ? depth0 : (container.nullContext || {}),"deactivated",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":19,"column":35},"end":{"line":19,"column":54}}}))
    + ")";
},"23":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = container.invokePartial(require("./status_emoji.hbs"),lookupProperty(depth0,"status_emoji_info"),{"name":"status_emoji","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"25":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<i class=\"zulip-icon zulip-icon-bot\" aria-label=\""
    + container.escapeExpression(lookupProperty(helpers,"t").call(depth0 != null ? depth0 : (container.nullContext || {}),"Bot",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":24,"column":61},"end":{"line":24,"column":73}}}))
    + "\"></i>";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class='pill "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"deactivated"),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":17},"end":{"line":1,"column":61}}})) != null ? stack1 : "")
    + "'"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"user_id"),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":62},"end":{"line":1,"column":110}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"group_id"),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":110},"end":{"line":1,"column":166}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"stream_id"),{"name":"if","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":166},"end":{"line":1,"column":220}}})) != null ? stack1 : "")
    + " tabindex=0>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"has_image"),{"name":"if","hash":{},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":2,"column":4},"end":{"line":4,"column":11}}})) != null ? stack1 : "")
    + "    <span class=\"pill-label\">\n        <span class=\"pill-value\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"has_stream"),{"name":"if","hash":{},"fn":container.program(11, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":7,"column":12},"end":{"line":15,"column":19}}})) != null ? stack1 : "")
    + "            "
    + container.escapeExpression(container.lambda(container.strict(depth0, "display_value", {"start":{"line":16,"column":15},"end":{"line":16,"column":28}} ), depth0))
    + "\n        </span>"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"should_add_guest_user_indicator"),{"name":"if","hash":{},"fn":container.program(19, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":18,"column":8},"end":{"line":18,"column":85}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"deactivated"),{"name":"if","hash":{},"fn":container.program(21, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":19,"column":8},"end":{"line":19,"column":64}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"has_status"),{"name":"if","hash":{},"fn":container.program(23, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":20,"column":8},"end":{"line":22,"column":17}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"is_bot"),{"name":"if","hash":{},"fn":container.program(25, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":23,"column":8},"end":{"line":25,"column":17}}})) != null ? stack1 : "")
    + "</span>\n    <div class=\"exit\">\n        <a role=\"button\" class=\"zulip-icon zulip-icon-close pill-close-button\"></a>\n    </div>\n</div>\n";
},"usePartial":true,"useData":true});