var Handlebars = require("../../node_modules/.pnpm/handlebars@4.7.8_patch_hash=c5t5wnxp6mvabdrpn7wm4zt2zu/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "        <div class=\"input-group\">\n            <label class=\"modal-field-label\">New channel</label>\n            "
    + ((stack1 = container.invokePartial(require("./dropdown_widget_wrapper.hbs"),undefined,{"name":"dropdown_widget_wrapper","hash":{"widget_name":"move_topic_to_stream"},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "        </div>\n";
},"3":function(container,depth0,helpers,partials,data) {
    return "disabled";
},"5":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <div class=\"input-group\">\n            <label for=\"message_move_select_options\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Which messages should be moved?",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":17,"column":53},"end":{"line":17,"column":92}}}))
    + "</label>\n            <select name=\"propagate_mode\" id=\"message_move_select_options\" class=\"message_edit_topic_propagate modal_select bootstrap-focus-style\">\n                <option value=\"change_one\" "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(helpers,"eq").call(alias1,lookupProperty(depth0,"message_placement"),"last",{"name":"eq","hash":{},"data":data,"loc":{"start":{"line":19,"column":49},"end":{"line":19,"column":78}}}),{"name":"if","hash":{},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":19,"column":43},"end":{"line":19,"column":95}}})) != null ? stack1 : "")
    + "> "
    + alias2(lookupProperty(helpers,"t").call(alias1,"Move only this message",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":19,"column":97},"end":{"line":19,"column":128}}}))
    + "</option>\n                <option value=\"change_later\" "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(helpers,"eq").call(alias1,lookupProperty(depth0,"message_placement"),"intermediate",{"name":"eq","hash":{},"data":data,"loc":{"start":{"line":20,"column":51},"end":{"line":20,"column":88}}}),{"name":"if","hash":{},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":20,"column":45},"end":{"line":20,"column":105}}})) != null ? stack1 : "")
    + "> "
    + alias2(lookupProperty(helpers,"t").call(alias1,"Move this and all following messages in this topic",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":20,"column":107},"end":{"line":20,"column":166}}}))
    + "</option>\n                <option value=\"change_all\" "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(helpers,"eq").call(alias1,lookupProperty(depth0,"message_placement"),"first",{"name":"eq","hash":{},"data":data,"loc":{"start":{"line":21,"column":49},"end":{"line":21,"column":79}}}),{"name":"if","hash":{},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":21,"column":43},"end":{"line":21,"column":96}}})) != null ? stack1 : "")
    + "> "
    + alias2(lookupProperty(helpers,"t").call(alias1,"Move all messages in this topic",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":21,"column":98},"end":{"line":21,"column":138}}}))
    + "</option>\n            </select>\n        </div>\n";
},"6":function(container,depth0,helpers,partials,data) {
    return "selected";
},"8":function(container,depth0,helpers,partials,data) {
    return "checked=\"checked\"";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.strict, alias3=container.lambda, alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<form id=\"move_topic_form\">\n    <div class=\"topic_stream_edit_header\">\n"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,lookupProperty(depth0,"only_topic_edit"),{"name":"unless","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":3,"column":8},"end":{"line":8,"column":19}}})) != null ? stack1 : "")
    + "        <div class=\"input-group\">\n            <label for=\"move-topic-new-topic-name\" class=\"modal-field-label\">New topic</label>\n            <input id=\"move-topic-new-topic-name\" name=\"new_topic_name\" type=\"text\" class=\"move_messages_edit_topic modal_text_input\" autocomplete=\"off\" value=\""
    + alias4(alias3(alias2(depth0, "topic_name", {"start":{"line":11,"column":162},"end":{"line":11,"column":172}} ), depth0))
    + "\" "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"disable_topic_input"),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":11,"column":176},"end":{"line":11,"column":218}}})) != null ? stack1 : "")
    + " />\n        </div>\n        <input name=\"old_topic_name\" type=\"hidden\" value=\""
    + alias4(alias3(alias2(depth0, "topic_name", {"start":{"line":13,"column":60},"end":{"line":13,"column":70}} ), depth0))
    + "\" />\n        <input name=\"current_stream_id\" type=\"hidden\" value=\""
    + alias4(alias3(alias2(depth0, "current_stream_id", {"start":{"line":14,"column":63},"end":{"line":14,"column":80}} ), depth0))
    + "\" />\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"from_message_actions_popover"),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":15,"column":8},"end":{"line":24,"column":15}}})) != null ? stack1 : "")
    + "        <p id=\"move_messages_count\"></p>\n        <div class=\"topic_move_breadcrumb_messages\">\n            <label class=\"checkbox\">\n                <input class=\"send_notification_to_new_thread\" name=\"send_notification_to_new_thread\" type=\"checkbox\" "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"notify_new_thread"),{"name":"if","hash":{},"fn":container.program(8, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":28,"column":118},"end":{"line":28,"column":167}}})) != null ? stack1 : "")
    + " />\n                <span class=\"rendered-checkbox\"></span>\n                "
    + alias4(lookupProperty(helpers,"t").call(alias1,"Send automated notice to new topic",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":30,"column":16},"end":{"line":30,"column":59}}}))
    + "\n            </label>\n            <label class=\"checkbox\">\n                <input class=\"send_notification_to_old_thread\" name=\"send_notification_to_old_thread\" type=\"checkbox\" "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"notify_old_thread"),{"name":"if","hash":{},"fn":container.program(8, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":33,"column":118},"end":{"line":33,"column":167}}})) != null ? stack1 : "")
    + " />\n                <span class=\"rendered-checkbox\"></span>\n                "
    + alias4(lookupProperty(helpers,"t").call(alias1,"Send automated notice to old topic",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":35,"column":16},"end":{"line":35,"column":59}}}))
    + "\n            </label>\n        </div>\n    </div>\n</form>\n";
},"usePartial":true,"useData":true});