var Handlebars = require("../../node_modules/.pnpm/handlebars@4.7.8_patch_hash=c5t5wnxp6mvabdrpn7wm4zt2zu/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.strict, alias2=container.lambda, alias3=container.escapeExpression, alias4=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"message_header message_header_stream right_part\" data-stream-id=\""
    + alias3(alias2(alias1(depth0, "stream_id", {"start":{"line":2,"column":79},"end":{"line":2,"column":88}} ), depth0))
    + "\">\n    <div class=\"message-header-contents\" style=\"background: "
    + alias3(alias2(alias1(depth0, "recipient_bar_color", {"start":{"line":3,"column":62},"end":{"line":3,"column":81}} ), depth0))
    + ";\">\n        <a class=\"message_label_clickable narrows_by_recipient stream_label tippy-narrow-tooltip\"\n          href=\""
    + alias3(alias2(alias1(depth0, "stream_url", {"start":{"line":6,"column":18},"end":{"line":6,"column":28}} ), depth0))
    + "\"\n          data-tippy-content=\""
    + alias3(lookupProperty(helpers,"t").call(alias4,"Go to #{display_recipient}",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":7,"column":30},"end":{"line":7,"column":65}}}))
    + "\">\n            <span class=\"stream-privacy-modified-color-"
    + alias3(alias2(alias1(depth0, "stream_id", {"start":{"line":8,"column":57},"end":{"line":8,"column":66}} ), depth0))
    + " stream-privacy filter-icon\"  style=\"color: "
    + alias3(alias2(alias1(depth0, "stream_privacy_icon_color", {"start":{"line":8,"column":114},"end":{"line":8,"column":139}} ), depth0))
    + "\">\n                "
    + ((stack1 = container.invokePartial(require("./stream_privacy.hbs"),depth0,{"name":"stream_privacy","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "            </span><span class=\"message-header-stream-name\">"
    + alias3(alias2(alias1(depth0, "display_recipient", {"start":{"line":14,"column":19},"end":{"line":14,"column":36}} ), depth0))
    + "</span>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias4,lookupProperty(depth0,"is_archived"),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":16,"column":12},"end":{"line":18,"column":19}}})) != null ? stack1 : "")
    + "        </a>\n        <span class=\"stream_topic_separator\"><i class=\"zulip-icon zulip-icon-chevron-right\"></i></span>\n\n        <span class=\"copy-paste-text\">&gt;</span>\n\n        <span class=\"stream_topic\">\n            <a class=\"message_label_clickable narrows_by_topic tippy-narrow-tooltip\"\n              href=\""
    + alias3(alias2(alias1(depth0, "topic_url", {"start":{"line":29,"column":22},"end":{"line":29,"column":31}} ), depth0))
    + "\"\n              data-tippy-content=\""
    + alias3(lookupProperty(helpers,"t").call(alias4,"Go to #{display_recipient} > {topic_display_name}",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":30,"column":34},"end":{"line":30,"column":92}}}))
    + "\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias4,lookupProperty(depth0,"use_match_properties"),{"name":"if","hash":{},"fn":container.program(4, data, 0),"inverse":container.program(6, data, 0),"data":data,"loc":{"start":{"line":31,"column":16},"end":{"line":35,"column":23}}})) != null ? stack1 : "")
    + "            </a>\n        </span>\n        <span class=\"recipient_bar_controls no-select\">\n            <span class=\"topic_edit hidden-for-spectators\">\n                <span class=\"topic_edit_form\"></span>\n            </span>\n\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias4,lookupProperty(depth0,"topic_links"),{"name":"each","hash":{},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":44,"column":12},"end":{"line":48,"column":21}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias4,lookupProperty(depth0,"always_visible_topic_edit"),{"name":"if","hash":{},"fn":container.program(11, data, 0),"inverse":container.program(13, data, 0),"data":data,"loc":{"start":{"line":51,"column":12},"end":{"line":55,"column":19}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias4,lookupProperty(depth0,"user_can_resolve_topic"),{"name":"if","hash":{},"fn":container.program(16, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":57,"column":12},"end":{"line":64,"column":19}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias4,lookupProperty(depth0,"is_subscribed"),{"name":"if","hash":{},"fn":container.program(21, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":66,"column":12},"end":{"line":82,"column":19}}})) != null ? stack1 : "")
    + "\n            <i class=\"zulip-icon zulip-icon-more-vertical recipient-row-topic-menu recipient_bar_icon\" tabindex=\"0\" aria-label=\""
    + alias3(lookupProperty(helpers,"t").call(alias4,"Topic actions menu",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":85,"column":128},"end":{"line":85,"column":155}}}))
    + "\" aria-haspopup=\"true\" data-stream-id=\""
    + alias3(alias2(alias1(depth0, "stream_id", {"start":{"line":85,"column":196},"end":{"line":85,"column":205}} ), depth0))
    + "\" data-topic-name=\""
    + alias3(alias2(alias1(depth0, "topic", {"start":{"line":85,"column":228},"end":{"line":85,"column":233}} ), depth0))
    + "\" data-topic-url=\""
    + alias3(alias2(alias1(depth0, "topic_url", {"start":{"line":85,"column":255},"end":{"line":85,"column":264}} ), depth0))
    + "\"></i>\n        </span>\n        <span class=\"recipient_row_date "
    + ((stack1 = lookupProperty(helpers,"if").call(alias4,lookupProperty(helpers,"and").call(alias4,lookupProperty(helpers,"not").call(alias4,lookupProperty(depth0,"always_display_date"),{"name":"not","hash":{},"data":data,"loc":{"start":{"line":87,"column":51},"end":{"line":87,"column":76}}}),lookupProperty(depth0,"date_unchanged"),{"name":"and","hash":{},"data":data,"loc":{"start":{"line":87,"column":46},"end":{"line":87,"column":93}}}),{"name":"if","hash":{},"fn":container.program(32, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":87,"column":40},"end":{"line":87,"column":130}}})) != null ? stack1 : "")
    + "\">"
    + ((stack1 = alias2(alias1(depth0, "date", {"start":{"line":87,"column":135},"end":{"line":87,"column":139}} ), depth0)) != null ? stack1 : "")
    + "</span>\n    </div>\n</div>\n";
},"2":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <span class=\"message-header-stream-archived\"><i class=\"archived-indicator\">("
    + container.escapeExpression(lookupProperty(helpers,"t").call(depth0 != null ? depth0 : (container.nullContext || {}),"archived",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":17,"column":88},"end":{"line":17,"column":105}}}))
    + ")</i></span>\n";
},"4":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "                    <span class=\"stream-topic-inner\">"
    + ((stack1 = container.lambda(container.strict(depth0, "match_topic", {"start":{"line":32,"column":56},"end":{"line":32,"column":67}} ), depth0)) != null ? stack1 : "")
    + "</span>\n";
},"6":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <span class=\"stream-topic-inner "
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),lookupProperty(depth0,"is_empty_string_topic"),{"name":"if","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":34,"column":52},"end":{"line":34,"column":107}}})) != null ? stack1 : "")
    + "\">"
    + container.escapeExpression(container.lambda(container.strict(depth0, "topic_display_name", {"start":{"line":34,"column":111},"end":{"line":34,"column":129}} ), depth0))
    + "</span>\n";
},"7":function(container,depth0,helpers,partials,data) {
    return "empty-topic-display";
},"9":function(container,depth0,helpers,partials,data) {
    var alias1=container.strict, alias2=container.lambda, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <a href=\""
    + alias3(alias2(alias1(depth0, "url", {"start":{"line":45,"column":27},"end":{"line":45,"column":35}} ), depth0))
    + "\" target=\"_blank\" rel=\"noopener noreferrer\" class=\"external-topic-link no-underline\">\n                    <i class=\"fa fa-external-link-square recipient_bar_icon\" data-tippy-content=\"Open "
    + alias3(alias2(alias1(depth0, "text", {"start":{"line":46,"column":104},"end":{"line":46,"column":113}} ), depth0))
    + "\" aria-label=\""
    + alias3(lookupProperty(helpers,"t").call(depth0 != null ? depth0 : (container.nullContext || {}),"External link",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":46,"column":129},"end":{"line":46,"column":151}}}))
    + "\"></i>\n                </a>\n";
},"11":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <i class=\"fa fa-pencil always_visible_topic_edit recipient_bar_icon hidden-for-spectators\" data-tippy-content=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"Edit topic",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":52,"column":127},"end":{"line":52,"column":145}}}))
    + "\" role=\"button\" tabindex=\"0\" aria-label=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"Edit topic",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":52,"column":186},"end":{"line":52,"column":205}}}))
    + "\"></i>\n";
},"13":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),lookupProperty(depth0,"on_hover_topic_edit"),{"name":"if","hash":{},"fn":container.program(14, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":53,"column":12},"end":{"line":55,"column":12}}})) != null ? stack1 : "");
},"14":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <i class=\"fa fa-pencil on_hover_topic_edit recipient_bar_icon hidden-for-spectators\" data-tippy-content=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"Edit topic",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":54,"column":121},"end":{"line":54,"column":139}}}))
    + "\" role=\"button\" tabindex=\"0\" aria-label=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"Edit topic",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":54,"column":180},"end":{"line":54,"column":199}}}))
    + "\"></i>\n            ";
},"16":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),lookupProperty(depth0,"topic_is_resolved"),{"name":"if","hash":{},"fn":container.program(17, data, 0),"inverse":container.program(19, data, 0),"data":data,"loc":{"start":{"line":58,"column":16},"end":{"line":62,"column":23}}})) != null ? stack1 : "")
    + "                <div class=\"toggle_resolve_topic_spinner\" style=\"display: none\"></div>\n";
},"17":function(container,depth0,helpers,partials,data) {
    var alias1=container.escapeExpression, alias2=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <i class=\"fa fa-check on_hover_topic_unresolve recipient_bar_icon hidden-for-spectators\" data-topic-name=\""
    + alias1(container.lambda(container.strict(depth0, "topic", {"start":{"line":59,"column":128},"end":{"line":59,"column":133}} ), depth0))
    + "\" data-tippy-content=\""
    + alias1(lookupProperty(helpers,"t").call(alias2,"Mark as unresolved",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":59,"column":157},"end":{"line":59,"column":184}}}))
    + "\" role=\"button\" tabindex=\"0\" aria-label=\""
    + alias1(lookupProperty(helpers,"t").call(alias2,"Mark as unresolved",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":59,"column":225},"end":{"line":59,"column":252}}}))
    + "\"></i>\n";
},"19":function(container,depth0,helpers,partials,data) {
    var alias1=container.escapeExpression, alias2=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <i class=\"fa fa-check on_hover_topic_resolve recipient_bar_icon hidden-for-spectators\" data-topic-name=\""
    + alias1(container.lambda(container.strict(depth0, "topic", {"start":{"line":61,"column":126},"end":{"line":61,"column":131}} ), depth0))
    + "\" data-tippy-content=\""
    + alias1(lookupProperty(helpers,"t").call(alias2,"Mark as resolved",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":61,"column":155},"end":{"line":61,"column":180}}}))
    + "\" role=\"button\" tabindex=\"0\" aria-label=\""
    + alias1(lookupProperty(helpers,"t").call(alias2,"Mark as resolved",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":61,"column":221},"end":{"line":61,"column":246}}}))
    + "\"></i>\n";
},"21":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.strict, alias2=container.lambda, alias3=container.escapeExpression, alias4=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <span class=\"change_visibility_policy hidden-for-spectators\" data-stream-id=\""
    + alias3(alias2(alias1(depth0, "stream_id", {"start":{"line":67,"column":95},"end":{"line":67,"column":104}} ), depth0))
    + "\" data-topic-name=\""
    + alias3(alias2(alias1(depth0, "topic", {"start":{"line":67,"column":127},"end":{"line":67,"column":132}} ), depth0))
    + "\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias4,lookupProperty(helpers,"eq").call(alias4,lookupProperty(depth0,"visibility_policy"),lookupProperty(lookupProperty(depth0,"all_visibility_policies"),"FOLLOWED"),{"name":"eq","hash":{},"data":data,"loc":{"start":{"line":68,"column":26},"end":{"line":68,"column":81}}}),{"name":"if","hash":{},"fn":container.program(22, data, 0),"inverse":container.program(24, data, 0),"data":data,"loc":{"start":{"line":68,"column":20},"end":{"line":80,"column":27}}})) != null ? stack1 : "")
    + "                </span>\n";
},"22":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        <i class=\"zulip-icon zulip-icon-follow recipient_bar_icon\" data-tippy-content=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"You follow this topic.",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":69,"column":103},"end":{"line":69,"column":133}}}))
    + "\"\n                          role=\"button\" tabindex=\"0\" aria-haspopup=\"true\" aria-label=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"You follow this topic.",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":70,"column":86},"end":{"line":70,"column":117}}}))
    + "\"></i>\n";
},"24":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(helpers,"eq").call(alias1,lookupProperty(depth0,"visibility_policy"),lookupProperty(lookupProperty(depth0,"all_visibility_policies"),"UNMUTED"),{"name":"eq","hash":{},"data":data,"loc":{"start":{"line":71,"column":30},"end":{"line":71,"column":84}}}),{"name":"if","hash":{},"fn":container.program(25, data, 0),"inverse":container.program(27, data, 0),"data":data,"loc":{"start":{"line":71,"column":20},"end":{"line":80,"column":20}}})) != null ? stack1 : "");
},"25":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        <i class=\"zulip-icon zulip-icon-unmute-new recipient_bar_icon\" data-tippy-content=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"You have unmuted this topic.",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":72,"column":107},"end":{"line":72,"column":143}}}))
    + "\"\n                          role=\"button\" tabindex=\"0\" aria-haspopup=\"true\" aria-label=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"You have unmuted this topic.",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":73,"column":86},"end":{"line":73,"column":123}}}))
    + "\"></i>\n";
},"27":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(helpers,"eq").call(alias1,lookupProperty(depth0,"visibility_policy"),lookupProperty(lookupProperty(depth0,"all_visibility_policies"),"MUTED"),{"name":"eq","hash":{},"data":data,"loc":{"start":{"line":74,"column":30},"end":{"line":74,"column":82}}}),{"name":"if","hash":{},"fn":container.program(28, data, 0),"inverse":container.program(30, data, 0),"data":data,"loc":{"start":{"line":74,"column":20},"end":{"line":80,"column":20}}})) != null ? stack1 : "");
},"28":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        <i class=\"zulip-icon zulip-icon-mute-new recipient_bar_icon\" data-tippy-content=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"You have muted this topic.",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":75,"column":105},"end":{"line":75,"column":139}}}))
    + "\"\n                          role=\"button\" tabindex=\"0\" aria-haspopup=\"true\" aria-label=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"You have muted this topic.",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":76,"column":86},"end":{"line":76,"column":121}}}))
    + "\"></i>\n";
},"30":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        <i class=\"zulip-icon zulip-icon-inherit recipient_bar_icon\"\n                          role=\"button\" tabindex=\"0\" aria-haspopup=\"true\" aria-label=\""
    + container.escapeExpression(lookupProperty(helpers,"t").call(depth0 != null ? depth0 : (container.nullContext || {}),"Notifications are based on your configuration for this channel.",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":79,"column":86},"end":{"line":79,"column":158}}}))
    + "\"></i>\n                    ";
},"32":function(container,depth0,helpers,partials,data) {
    return "recipient_row_date_unchanged";
},"34":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=container.strict, alias2=container.lambda, alias3=container.escapeExpression, alias4=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"message_header message_header_private_message\">\n    <div class=\"message-header-contents\">\n        <a class=\"message_label_clickable narrows_by_recipient stream_label tippy-narrow-tooltip\"\n          href=\""
    + alias3(alias2(alias1(depth0, "pm_with_url", {"start":{"line":94,"column":18},"end":{"line":94,"column":29}} ), depth0))
    + "\"\n          data-tippy-content=\""
    + alias3(lookupProperty(helpers,"t").call(alias4,"Go to direct messages with {display_reply_to_for_tooltip}",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":95,"column":30},"end":{"line":95,"column":96}}}))
    + "\">\n        <span class=\"private_message_header_icon\"><i class=\"zulip-icon zulip-icon-user\"></i></span>\n        <span class=\"private_message_header_name\">"
    + ((stack1 = lookupProperty(helpers,"tr").call(alias4,{"name":"tr","hash":{},"fn":container.program(35, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":98,"column":12},"end":{"line":104,"column":20}}})) != null ? stack1 : "")
    + "</span>\n        </a>\n        <span class=\"recipient_row_date "
    + ((stack1 = lookupProperty(helpers,"if").call(alias4,lookupProperty(helpers,"and").call(alias4,lookupProperty(helpers,"not").call(alias4,lookupProperty(depth0,"always_display_date"),{"name":"not","hash":{},"data":data,"loc":{"start":{"line":107,"column":51},"end":{"line":107,"column":76}}}),lookupProperty(depth0,"date_unchanged"),{"name":"and","hash":{},"data":data,"loc":{"start":{"line":107,"column":46},"end":{"line":107,"column":93}}}),{"name":"if","hash":{},"fn":container.program(32, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":107,"column":40},"end":{"line":107,"column":130}}})) != null ? stack1 : "")
    + "\">"
    + ((stack1 = alias2(alias1(depth0, "date", {"start":{"line":107,"column":135},"end":{"line":107,"column":139}} ), depth0)) != null ? stack1 : "")
    + "</span>\n    </div>\n</div>\n";
},"35":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "You and <z-user-names></z-user-names>\n";
},"36":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"each").call(depth0 != null ? depth0 : (container.nullContext || {}),lookupProperty(depth0,"recipient_users"),{"name":"each","hash":{},"fn":container.program(37, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":100,"column":16},"end":{"line":102,"column":25}}})) != null ? stack1 : "");
},"37":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return container.escapeExpression(container.lambda(container.strict(depth0, "full_name", {"start":{"line":101,"column":23},"end":{"line":101,"column":32}} ), depth0))
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"should_add_guest_user_indicator"),{"name":"if","hash":{},"fn":container.program(38, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":101,"column":34},"end":{"line":101,"column":108}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,lookupProperty(data,"last"),{"name":"unless","hash":{},"fn":container.program(40, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":101,"column":108},"end":{"line":101,"column":139}}})) != null ? stack1 : "");
},"38":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "&nbsp;<i>("
    + container.escapeExpression(lookupProperty(helpers,"t").call(depth0 != null ? depth0 : (container.nullContext || {}),"guest",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":101,"column":83},"end":{"line":101,"column":96}}}))
    + ")</i>";
},"40":function(container,depth0,helpers,partials,data) {
    return ", ";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),lookupProperty(depth0,"is_stream"),{"name":"if","hash":{},"fn":container.program(1, data, 0, blockParams, depths),"inverse":container.program(34, data, 0, blockParams, depths),"data":data,"loc":{"start":{"line":1,"column":0},"end":{"line":110,"column":7}}})) != null ? stack1 : "");
},"35_d":  function(fn, props, container, depth0, data, blockParams, depths) {

  var decorators = container.decorators, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  fn = lookupProperty(decorators,"inline")(fn,props,container,{"name":"inline","hash":{},"fn":container.program(36, data, 0, blockParams, depths),"inverse":container.noop,"args":["z-user-names"],"data":data,"loc":{"start":{"line":99,"column":12},"end":{"line":103,"column":23}}}) || fn;
  return fn;
  }

,"useDecorators":true,"usePartial":true,"useData":true,"useDepths":true});