var Handlebars = require("../../../node_modules/.pnpm/handlebars@4.7.8_patch_hash=c5t5wnxp6mvabdrpn7wm4zt2zu/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "    "
    + ((stack1 = container.invokePartial(require("./inbox_stream_header_row.hbs"),depth0,{"name":"inbox_stream_header_row","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"3":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.strict, alias2=container.lambda, alias3=container.escapeExpression, alias4=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <div id=\"inbox-row-conversation-"
    + alias3(alias2(alias1(depth0, "conversation_key", {"start":{"line":4,"column":38},"end":{"line":4,"column":54}} ), depth0))
    + "\" class=\"inbox-row "
    + ((stack1 = lookupProperty(helpers,"if").call(alias4,lookupProperty(depth0,"is_hidden"),{"name":"if","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":4,"column":75},"end":{"line":4,"column":116}}})) != null ? stack1 : "")
    + " "
    + ((stack1 = lookupProperty(helpers,"if").call(alias4,lookupProperty(depth0,"is_collapsed"),{"name":"if","hash":{},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":4,"column":117},"end":{"line":4,"column":163}}})) != null ? stack1 : "")
    + "\" tabindex=\"0\" data-col-index=\""
    + alias3(alias2(alias1(lookupProperty(depth0,"column_indexes"), "RECIPIENT", {"start":{"line":4,"column":197},"end":{"line":4,"column":221}} ), depth0))
    + "\">\n        <div class=\"inbox-focus-border\">\n            <div class=\"inbox-left-part-wrapper\">\n                <div class=\"inbox-left-part\">\n                    <div class=\"hide fake-collapse-button\" tabindex=\"0\" data-col-index=\""
    + alias3(alias2(alias1(lookupProperty(depth0,"column_indexes"), "COLLAPSE_BUTTON", {"start":{"line":8,"column":91},"end":{"line":8,"column":121}} ), depth0))
    + "\"></div>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias4,lookupProperty(depth0,"is_direct"),{"name":"if","hash":{},"fn":container.program(8, data, 0),"inverse":container.program(18, data, 0),"data":data,"loc":{"start":{"line":9,"column":20},"end":{"line":43,"column":27}}})) != null ? stack1 : "")
    + "                </div>\n            </div>\n"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias4,lookupProperty(depth0,"is_direct"),{"name":"unless","hash":{},"fn":container.program(24, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":46,"column":12},"end":{"line":74,"column":23}}})) != null ? stack1 : "")
    + "        </div>\n    </div>\n";
},"4":function(container,depth0,helpers,partials,data) {
    return "hidden_by_filters";
},"6":function(container,depth0,helpers,partials,data) {
    return "collapsed_container";
},"8":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.strict, alias3=container.lambda, alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        <a class=\"recipients_info "
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,lookupProperty(depth0,"user_circle_class"),{"name":"unless","hash":{},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":10,"column":50},"end":{"line":10,"column":111}}})) != null ? stack1 : "")
    + "\" href=\""
    + alias4(alias3(alias2(depth0, "dm_url", {"start":{"line":10,"column":121},"end":{"line":10,"column":127}} ), depth0))
    + "\" tabindex=\"-1\">\n                            <span class=\"user_block\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"is_bot"),{"name":"if","hash":{},"fn":container.program(11, data, 0),"inverse":container.program(13, data, 0),"data":data,"loc":{"start":{"line":12,"column":32},"end":{"line":18,"column":39}}})) != null ? stack1 : "")
    + "                                <span class=\"recipients_name\">"
    + ((stack1 = alias3(alias2(depth0, "rendered_dm_with", {"start":{"line":19,"column":65},"end":{"line":19,"column":81}} ), depth0)) != null ? stack1 : "")
    + "</span>\n                            </span>\n                        </a>\n                        <div class=\"unread-count-focus-outline\" tabindex=\"0\" data-col-index=\""
    + alias4(alias3(alias2(lookupProperty(depth0,"column_indexes"), "UNREAD_COUNT", {"start":{"line":22,"column":96},"end":{"line":22,"column":123}} ), depth0))
    + "\">\n                            <span class=\"unread_count tippy-zulip-tooltip on_hover_dm_read\"\n                              data-user-ids-string=\""
    + alias4(alias3(alias2(depth0, "user_ids_string", {"start":{"line":24,"column":54},"end":{"line":24,"column":69}} ), depth0))
    + "\" data-tippy-content=\""
    + alias4(lookupProperty(helpers,"t").call(alias1,"Mark as read",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":24,"column":93},"end":{"line":24,"column":114}}}))
    + "\"\n                              aria-label=\""
    + alias4(lookupProperty(helpers,"t").call(alias1,"Mark as read",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":25,"column":42},"end":{"line":25,"column":63}}}))
    + "\">"
    + alias4(alias3(alias2(depth0, "unread_count", {"start":{"line":25,"column":67},"end":{"line":25,"column":79}} ), depth0))
    + "</span>\n                        </div>\n";
},"9":function(container,depth0,helpers,partials,data) {
    return "inbox-group-or-bot-dm";
},"11":function(container,depth0,helpers,partials,data) {
    return "                                <span class=\"zulip-icon zulip-icon-bot\" aria-hidden=\"true\"></span>\n";
},"13":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),lookupProperty(depth0,"is_group"),{"name":"if","hash":{},"fn":container.program(14, data, 0),"inverse":container.program(16, data, 0),"data":data,"loc":{"start":{"line":14,"column":32},"end":{"line":18,"column":32}}})) != null ? stack1 : "");
},"14":function(container,depth0,helpers,partials,data) {
    return "                                <span class=\"zulip-icon zulip-icon-triple-users\" aria-hidden=\"true\"></span>\n";
},"16":function(container,depth0,helpers,partials,data) {
    var alias1=container.strict, alias2=container.lambda, alias3=container.escapeExpression;

  return "                                <span class=\"zulip-icon zulip-icon-"
    + alias3(alias2(alias1(depth0, "user_circle_class", {"start":{"line":17,"column":69},"end":{"line":17,"column":86}} ), depth0))
    + " "
    + alias3(alias2(alias1(depth0, "user_circle_class", {"start":{"line":17,"column":91},"end":{"line":17,"column":108}} ), depth0))
    + " user-circle\" data-presence-indicator-user-id=\""
    + alias3(alias2(alias1(depth0, "user_ids_string", {"start":{"line":17,"column":159},"end":{"line":17,"column":174}} ), depth0))
    + "\"></span>\n                                ";
},"18":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),lookupProperty(depth0,"is_topic"),{"name":"if","hash":{},"fn":container.program(19, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":27,"column":20},"end":{"line":43,"column":20}}})) != null ? stack1 : "");
},"19":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.strict, alias2=container.lambda, alias3=container.escapeExpression, alias4=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        <span class=\"user-circle-active user-circle invisible\"></span>\n                        <div class=\"inbox-topic-name\">\n                            <a tabindex=\"-1\" href=\""
    + alias3(alias2(alias1(depth0, "topic_url", {"start":{"line":31,"column":53},"end":{"line":31,"column":62}} ), depth0))
    + "\" "
    + ((stack1 = lookupProperty(helpers,"if").call(alias4,lookupProperty(depth0,"is_empty_string_topic"),{"name":"if","hash":{},"fn":container.program(20, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":31,"column":66},"end":{"line":31,"column":129}}})) != null ? stack1 : "")
    + ">"
    + alias3(alias2(alias1(depth0, "topic_display_name", {"start":{"line":31,"column":132},"end":{"line":31,"column":150}} ), depth0))
    + "</a>\n                        </div>\n                        <span class=\"unread_mention_info tippy-zulip-tooltip\n                          "
    + ((stack1 = lookupProperty(helpers,"unless").call(alias4,lookupProperty(depth0,"mention_in_unread"),{"name":"unless","hash":{},"fn":container.program(22, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":34,"column":26},"end":{"line":34,"column":72}}})) != null ? stack1 : "")
    + "\"\n                          data-tippy-content=\""
    + alias3(lookupProperty(helpers,"t").call(alias4,"You have mentions",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":35,"column":46},"end":{"line":35,"column":71}}}))
    + "\">@</span>\n                        <div class=\"unread-count-focus-outline\" tabindex=\"0\" data-col-index=\""
    + alias3(alias2(alias1(lookupProperty(depth0,"column_indexes"), "UNREAD_COUNT", {"start":{"line":36,"column":96},"end":{"line":36,"column":123}} ), depth0))
    + "\">\n                            <span class=\"unread_count tippy-zulip-tooltip on_hover_topic_read\"\n                              data-stream-id=\""
    + alias3(alias2(alias1(depth0, "stream_id", {"start":{"line":38,"column":48},"end":{"line":38,"column":57}} ), depth0))
    + "\" data-topic-name=\""
    + alias3(alias2(alias1(depth0, "topic_name", {"start":{"line":38,"column":80},"end":{"line":38,"column":90}} ), depth0))
    + "\"\n                              data-tippy-content=\""
    + alias3(lookupProperty(helpers,"t").call(alias4,"Mark as read",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":39,"column":50},"end":{"line":39,"column":71}}}))
    + "\" aria-label=\""
    + alias3(lookupProperty(helpers,"t").call(alias4,"Mark as read",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":39,"column":85},"end":{"line":39,"column":106}}}))
    + "\">\n                                "
    + alias3(alias2(alias1(depth0, "unread_count", {"start":{"line":40,"column":34},"end":{"line":40,"column":46}} ), depth0))
    + "\n                            </span>\n                        </div>\n                    ";
},"20":function(container,depth0,helpers,partials,data) {
    return "class=\"empty-topic-display\"";
},"22":function(container,depth0,helpers,partials,data) {
    return "hidden";
},"24":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <div class=\"inbox-right-part-wrapper\">\n                <div class=\"inbox-right-part\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"is_topic"),{"name":"if","hash":{},"fn":container.program(25, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":49,"column":20},"end":{"line":66,"column":27}}})) != null ? stack1 : "")
    + "                    <div class=\"inbox-action-button inbox-topic-menu\"\n                      "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"is_topic"),{"name":"if","hash":{},"fn":container.program(39, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":68,"column":22},"end":{"line":69,"column":59}}})) != null ? stack1 : "")
    + " tabindex=\"0\" data-col-index=\""
    + container.escapeExpression(container.lambda(container.strict(lookupProperty(depth0,"column_indexes"), "ACTION_MENU", {"start":{"line":69,"column":92},"end":{"line":69,"column":118}} ), depth0))
    + "\">\n                        <i class=\"zulip-icon zulip-icon-more-vertical\" aria-hidden=\"true\"></i>\n                    </div>\n                </div>\n            </div>\n";
},"25":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.strict, alias3=container.lambda, alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        <span class=\"visibility-policy-indicator change_visibility_policy hidden-for-spectators"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(helpers,"eq").call(alias1,lookupProperty(depth0,"visibility_policy"),lookupProperty(lookupProperty(depth0,"all_visibility_policies"),"INHERIT"),{"name":"eq","hash":{},"data":data,"loc":{"start":{"line":50,"column":117},"end":{"line":50,"column":171}}}),{"name":"if","hash":{},"fn":container.program(26, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":50,"column":111},"end":{"line":50,"column":216}}})) != null ? stack1 : "")
    + "\"\n                          data-stream-id=\""
    + alias4(alias3(alias2(depth0, "stream_id", {"start":{"line":51,"column":44},"end":{"line":51,"column":53}} ), depth0))
    + "\" data-topic-name=\""
    + alias4(alias3(alias2(depth0, "topic_name", {"start":{"line":51,"column":76},"end":{"line":51,"column":86}} ), depth0))
    + "\" tabindex=\"0\" data-col-index=\""
    + alias4(alias3(alias2(lookupProperty(depth0,"column_indexes"), "TOPIC_VISIBILITY", {"start":{"line":51,"column":122},"end":{"line":51,"column":153}} ), depth0))
    + "\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(helpers,"eq").call(alias1,lookupProperty(depth0,"visibility_policy"),lookupProperty(lookupProperty(depth0,"all_visibility_policies"),"FOLLOWED"),{"name":"eq","hash":{},"data":data,"loc":{"start":{"line":52,"column":34},"end":{"line":52,"column":89}}}),{"name":"if","hash":{},"fn":container.program(28, data, 0),"inverse":container.program(30, data, 0),"data":data,"loc":{"start":{"line":52,"column":28},"end":{"line":64,"column":35}}})) != null ? stack1 : "")
    + "                        </span>\n";
},"26":function(container,depth0,helpers,partials,data) {
    return " inbox-row-visibility-policy-inherit";
},"28":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                <i class=\"zulip-icon zulip-icon-follow recipient_bar_icon\" data-tippy-content=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"You follow this topic.",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":53,"column":111},"end":{"line":53,"column":141}}}))
    + "\"\n                                  role=\"button\" aria-haspopup=\"true\" aria-label=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"You follow this topic.",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":54,"column":81},"end":{"line":54,"column":112}}}))
    + "\"></i>\n";
},"30":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(helpers,"eq").call(alias1,lookupProperty(depth0,"visibility_policy"),lookupProperty(lookupProperty(depth0,"all_visibility_policies"),"UNMUTED"),{"name":"eq","hash":{},"data":data,"loc":{"start":{"line":55,"column":38},"end":{"line":55,"column":92}}}),{"name":"if","hash":{},"fn":container.program(31, data, 0),"inverse":container.program(33, data, 0),"data":data,"loc":{"start":{"line":55,"column":28},"end":{"line":64,"column":28}}})) != null ? stack1 : "");
},"31":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                <i class=\"zulip-icon zulip-icon-unmute-new recipient_bar_icon\" data-tippy-content=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"You have unmuted this topic.",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":56,"column":115},"end":{"line":56,"column":151}}}))
    + "\"\n                                  role=\"button\" aria-haspopup=\"true\" aria-label=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"You have unmuted this topic.",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":57,"column":81},"end":{"line":57,"column":118}}}))
    + "\"></i>\n";
},"33":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(helpers,"eq").call(alias1,lookupProperty(depth0,"visibility_policy"),lookupProperty(lookupProperty(depth0,"all_visibility_policies"),"MUTED"),{"name":"eq","hash":{},"data":data,"loc":{"start":{"line":58,"column":38},"end":{"line":58,"column":90}}}),{"name":"if","hash":{},"fn":container.program(34, data, 0),"inverse":container.program(36, data, 0),"data":data,"loc":{"start":{"line":58,"column":28},"end":{"line":64,"column":28}}})) != null ? stack1 : "");
},"34":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                <i class=\"zulip-icon zulip-icon-mute-new recipient_bar_icon\" data-tippy-content=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"You have muted this topic.",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":59,"column":113},"end":{"line":59,"column":147}}}))
    + "\"\n                                  role=\"button\" aria-haspopup=\"true\" aria-label=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"You have muted this topic.",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":60,"column":81},"end":{"line":60,"column":116}}}))
    + "\"></i>\n";
},"36":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(helpers,"eq").call(alias1,lookupProperty(depth0,"visibility_policy"),lookupProperty(lookupProperty(depth0,"all_visibility_policies"),"INHERIT"),{"name":"eq","hash":{},"data":data,"loc":{"start":{"line":61,"column":38},"end":{"line":61,"column":92}}}),{"name":"if","hash":{},"fn":container.program(37, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":61,"column":28},"end":{"line":64,"column":28}}})) != null ? stack1 : "");
},"37":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                <i class=\"zulip-icon zulip-icon-inherit recipient_bar_icon\"\n                                  role=\"button\" aria-haspopup=\"true\" aria-label=\""
    + container.escapeExpression(lookupProperty(helpers,"t").call(depth0 != null ? depth0 : (container.nullContext || {}),"Notifications are based on your configuration for this channel.",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":63,"column":81},"end":{"line":63,"column":153}}}))
    + "\"></i>\n                            ";
},"39":function(container,depth0,helpers,partials,data) {
    var alias1=container.strict, alias2=container.lambda, alias3=container.escapeExpression;

  return "data-stream-id=\""
    + alias3(alias2(alias1(depth0, "stream_id", {"start":{"line":68,"column":56},"end":{"line":68,"column":65}} ), depth0))
    + "\" data-topic-name=\""
    + alias3(alias2(alias1(depth0, "topic_name", {"start":{"line":68,"column":88},"end":{"line":68,"column":98}} ), depth0))
    + "\"\n                      data-topic-url=\""
    + alias3(alias2(alias1(depth0, "topic_url", {"start":{"line":69,"column":40},"end":{"line":69,"column":49}} ), depth0))
    + "\"";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),lookupProperty(depth0,"is_stream"),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data,"loc":{"start":{"line":1,"column":0},"end":{"line":77,"column":7}}})) != null ? stack1 : "");
},"usePartial":true,"useData":true});