var Handlebars = require("../../../../node_modules/.pnpm/handlebars@4.7.8_patch_hash=c5t5wnxp6mvabdrpn7wm4zt2zu/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return " show_in_popover ";
},"3":function(container,depth0,helpers,partials,data) {
    return " tabindex=0 ";
},"5":function(container,depth0,helpers,partials,data) {
    return "        <div class=\"divider\"></div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"compose-control-buttons-container preview_mode_disabled "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"inside_popover"),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":68},"end":{"line":1,"column":114}}})) != null ? stack1 : "")
    + "\">\n    <a role=\"button\" data-format-type=\"link\" class=\"compose_control_button zulip-icon zulip-icon-link formatting_button\" aria-label=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"Link",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":2,"column":133},"end":{"line":2,"column":146}}}))
    + "\" "
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,lookupProperty(depth0,"preview_mode_on"),{"name":"unless","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":2,"column":148},"end":{"line":2,"column":198}}})) != null ? stack1 : "")
    + " data-tooltip-template-id=\"link-tooltip\" data-tippy-maxWidth=\"none\"></a>\n    <a role=\"button\" data-format-type=\"bold\" class=\"compose_control_button zulip-icon zulip-icon-bold formatting_button\" aria-label=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"Bold",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":3,"column":133},"end":{"line":3,"column":146}}}))
    + "\" "
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,lookupProperty(depth0,"preview_mode_on"),{"name":"unless","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":3,"column":148},"end":{"line":3,"column":198}}})) != null ? stack1 : "")
    + " data-tooltip-template-id=\"bold-tooltip\" data-tippy-maxWidth=\"none\"></a>\n    <a role=\"button\" data-format-type=\"italic\" class=\"compose_control_button zulip-icon zulip-icon-italic formatting_button\" aria-label=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"Italic",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":4,"column":137},"end":{"line":4,"column":152}}}))
    + "\" "
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,lookupProperty(depth0,"preview_mode_on"),{"name":"unless","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":4,"column":154},"end":{"line":4,"column":204}}})) != null ? stack1 : "")
    + " data-tooltip-template-id=\"italic-tooltip\" data-tippy-maxWidth=\"none\"></a>\n    <a role=\"button\" data-format-type=\"strikethrough\" class=\"compose_control_button zulip-icon zulip-icon-strikethrough formatting_button\" aria-label=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"Strikethrough",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":5,"column":151},"end":{"line":5,"column":173}}}))
    + "\" "
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,lookupProperty(depth0,"preview_mode_on"),{"name":"unless","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":5,"column":175},"end":{"line":5,"column":225}}})) != null ? stack1 : "")
    + " data-tippy-content=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"Strikethrough",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":5,"column":246},"end":{"line":5,"column":268}}}))
    + "\"></a>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"inside_popover"),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":6,"column":4},"end":{"line":8,"column":11}}})) != null ? stack1 : "")
    + "</div>\n";
},"useData":true});