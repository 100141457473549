var Handlebars = require("../../node_modules/.pnpm/handlebars@4.7.8_patch_hash=c5t5wnxp6mvabdrpn7wm4zt2zu/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"overlay-modal\" id=\"keyboard-shortcuts\" tabindex=\"-1\" role=\"dialog\"\n  aria-label=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"Keyboard shortcuts",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":2,"column":14},"end":{"line":2,"column":41}}}))
    + "\">\n    <div class=\"overlay-scroll-container\" data-simplebar data-simplebar-tab-index=\"-1\" data-simplebar-auto-hide=\"false\">\n        <div>\n            <table class=\"hotkeys_table table table-striped table-bordered\">\n                <thead>\n                    <tr>\n                        <th colspan=\"2\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"The basics",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":8,"column":40},"end":{"line":8,"column":59}}}))
    + "</th>\n                    </tr>\n                </thead>\n                <tr>\n                    <td class=\"definition\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Reply to message",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":12,"column":43},"end":{"line":12,"column":68}}}))
    + "</td>\n                    <td><span class=\"hotkey\"><kbd>Enter</kbd> or <kbd>R</kbd></span></td>\n                </tr>\n                <tr>\n                    <td class=\"definition\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"New channel message",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":16,"column":43},"end":{"line":16,"column":71}}}))
    + "</td>\n                    <td><span class=\"hotkey\"><kbd>C</kbd></span></td>\n                </tr>\n                <tr>\n                    <td class=\"definition\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"New direct message",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":20,"column":43},"end":{"line":20,"column":70}}}))
    + "</td>\n                    <td><span class=\"hotkey\"><kbd>X</kbd></span></td>\n                </tr>\n                <tr>\n                    <td class=\"definition\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Paste formatted text",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":24,"column":43},"end":{"line":24,"column":72}}}))
    + "</td>\n                    <td><span class=\"hotkey\"><kbd>Ctrl</kbd> + <kbd>V</kbd></span></td>\n                </tr>\n                <tr>\n                    <td class=\"definition\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Paste as plain text",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":28,"column":43},"end":{"line":28,"column":71}}}))
    + "</td>\n                    <td><span class=\"hotkey\"><kbd data-mac-following-key=\"⌥\">Ctrl</kbd> + <kbd>Shift</kbd> + <kbd>V</kbd></span></td>\n                </tr>\n                <tr>\n                    <td class=\"definition\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Cancel compose and save draft",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":32,"column":43},"end":{"line":32,"column":81}}}))
    + "</td>\n                    <td><span class=\"hotkey\"><kbd>Esc</kbd> or <kbd data-mac-key=\"Ctrl\">Ctrl</kbd> + <kbd>[</kbd></span></td>\n                </tr>\n                <tr>\n                    <td class=\"definition\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"View drafts",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":36,"column":43},"end":{"line":36,"column":63}}}))
    + "</td>\n                    <td><span class=\"hotkey\"><kbd>D</kbd></span></td>\n                </tr>\n                <tr>\n                    <td class=\"definition\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Next message",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":40,"column":43},"end":{"line":40,"column":64}}}))
    + "</td>\n                    <td><span class=\"hotkey\"><kbd class=\"arrow-key\">↓</kbd> or <kbd>J</kbd></span></td>\n                </tr>\n                <tr>\n                    <td class=\"definition\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Last message",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":44,"column":43},"end":{"line":44,"column":64}}}))
    + "</td>\n                    <td><span class=\"hotkey\"><kbd>End</kbd> or <kbd>Shift</kbd> + <kbd>G</kbd></span></td>\n                </tr>\n                <tr>\n                    <td class=\"definition\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Next unread topic",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":48,"column":43},"end":{"line":48,"column":69}}}))
    + "</td>\n                    <td><span class=\"hotkey\"><kbd>N</kbd></span></td>\n                </tr>\n                <tr>\n                    <td class=\"definition\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Next unread followed topic",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":52,"column":43},"end":{"line":52,"column":78}}}))
    + "</td>\n                    <td><span class=\"hotkey\"><kbd>Shift</kbd> + <kbd>N</kbd></span></td>\n                </tr>\n                <tr>\n                    <td class=\"definition\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Next unread direct message",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":56,"column":43},"end":{"line":56,"column":78}}}))
    + "</td>\n                    <td><span class=\"hotkey\"><kbd>P</kbd></span></td>\n                </tr>\n                <tr>\n                    <td class=\"definition\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Initiate a search",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":60,"column":43},"end":{"line":60,"column":69}}}))
    + "</td>\n                    <td><span class=\"hotkey\"><kbd>Ctrl</kbd> + <kbd>K</kbd> or <kbd>/</kbd></span></td>\n                </tr>\n                <tr>\n                    <td class=\"definition\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Show keyboard shortcuts",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":64,"column":43},"end":{"line":64,"column":75}}}))
    + "</td>\n                    <td><span class=\"hotkey\"><kbd>?</kbd></span></td>\n                </tr>\n                <tr>\n                    <td class=\"definition\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Go to your home view",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":68,"column":43},"end":{"line":68,"column":72}}}))
    + "</td>\n                    <td><span class=\"hotkey\"><kbd data-mac-key=\"Ctrl\">Ctrl</kbd> + <kbd>[</kbd><span id=\"go-to-home-view-hotkey-help\"> or <kbd>Esc</kbd></span></span></td>\n                </tr>\n            </table>\n        </div>\n        <div>\n            <table class=\"hotkeys_table table table-striped table-bordered\">\n                <thead>\n                    <tr>\n                        <th colspan=\"2\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Search",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":77,"column":40},"end":{"line":77,"column":55}}}))
    + "</th>\n                    </tr>\n                </thead>\n                <tr>\n                    <td class=\"definition\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Initiate a search",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":81,"column":43},"end":{"line":81,"column":69}}}))
    + "</td>\n                    <td><span class=\"hotkey\"><kbd>Ctrl</kbd> + <kbd>K</kbd> or <kbd>/</kbd></span></td>\n                </tr>\n                <tr>\n                    <td class=\"definition\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Filter channels",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":85,"column":43},"end":{"line":85,"column":67}}}))
    + "</td>\n                    <td><span class=\"hotkey\"><kbd>Q</kbd></span></td>\n                </tr>\n                <tr>\n                    <td class=\"definition\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Filter users",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":89,"column":43},"end":{"line":89,"column":64}}}))
    + "</td>\n                    <td><span class=\"hotkey\"><kbd>W</kbd></span></td>\n                </tr>\n            </table>\n        </div>\n        <div>\n            <table class=\"hotkeys_table table table-striped table-bordered\">\n                <thead>\n                    <tr>\n                        <th colspan=\"2\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Scrolling",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":98,"column":40},"end":{"line":98,"column":58}}}))
    + "</th>\n                    </tr>\n                </thead>\n                <tr>\n                    <td class=\"definition\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Previous message",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":102,"column":43},"end":{"line":102,"column":68}}}))
    + "</td>\n                    <td><span class=\"hotkey\"><kbd class=\"arrow-key\">↑</kbd> or <kbd>K</kbd></span></td>\n                </tr>\n                <tr>\n                    <td class=\"definition\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Next message",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":106,"column":43},"end":{"line":106,"column":64}}}))
    + "</td>\n                    <td><span class=\"hotkey\"><kbd class=\"arrow-key\">↓</kbd> or <kbd>J</kbd></span></td>\n                </tr>\n                <tr>\n                    <td class=\"definition\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Scroll up",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":110,"column":43},"end":{"line":110,"column":61}}}))
    + "</td>\n                    <td><span class=\"hotkey\"><kbd>PgUp</kbd> or <kbd>Fn</kbd> + <kbd class=\"arrow-key\">↑</kbd> or <kbd>Shift</kbd> + <kbd>K</kbd></span></td>\n                </tr>\n                <tr>\n                    <td class=\"definition\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Scroll down",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":114,"column":43},"end":{"line":114,"column":63}}}))
    + "</td>\n                    <td><span class=\"hotkey\"><kbd>PgDn</kbd> or <kbd>Fn</kbd> + <kbd class=\"arrow-key\">↓</kbd> or <kbd>Shift</kbd> + <kbd>J</kbd> or <kbd>Space</kbd></span></td>\n                </tr>\n                <tr>\n                    <td class=\"definition\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Last message",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":118,"column":43},"end":{"line":118,"column":64}}}))
    + "</td>\n                    <td><span class=\"hotkey\"><kbd>End</kbd> or <kbd>Fn</kbd> + <kbd class=\"arrow-key\">→</kbd> or <kbd>Shift</kbd> + <kbd>G</kbd></span></td>\n                </tr>\n                <tr>\n                    <td class=\"definition\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"First message",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":122,"column":43},"end":{"line":122,"column":65}}}))
    + "</td>\n                    <td><span class=\"hotkey\"><kbd>Home</kbd >or <kbd>Fn</kbd> + <kbd class=\"arrow-key\">←</kbd></span></td>\n                </tr>\n            </table>\n        </div>\n        <div>\n            <table class=\"hotkeys_table table table-striped table-bordered\">\n                <thead>\n                    <tr>\n                        <th colspan=\"2\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Navigation",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":131,"column":40},"end":{"line":131,"column":59}}}))
    + "</th>\n                    </tr>\n                </thead>\n                <tr>\n                    <td class=\"definition\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Go back through viewing history",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":135,"column":43},"end":{"line":135,"column":83}}}))
    + "</td>\n                    <td><span class=\"hotkey\"><kbd data-mac-key=\"⌘\">Alt</kbd> + <kbd class=\"arrow-key\">←</kbd></span></td>\n                </tr>\n                <tr>\n                    <td class=\"definition\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Go forward through viewing history",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":139,"column":43},"end":{"line":139,"column":86}}}))
    + "</td>\n                    <td><span class=\"hotkey\"><kbd data-mac-key=\"⌘\">Alt</kbd> + <kbd class=\"arrow-key\">→</kbd></span></td>\n                </tr>\n                <tr>\n                    <td class=\"definition\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Go to topic or DM conversation",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":143,"column":43},"end":{"line":143,"column":82}}}))
    + "</td>\n                    <td><span class=\"hotkey\"><kbd>S</kbd></span></td>\n                </tr>\n                <tr>\n                    <td class=\"definition\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Go to channel feed from topic view",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":147,"column":43},"end":{"line":147,"column":86}}}))
    + "</td>\n                    <td><span class=\"hotkey\"><kbd>S</kbd></span></td>\n                </tr>\n                <tr>\n                    <td class=\"definition\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Go to direct message feed",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":151,"column":43},"end":{"line":151,"column":77}}}))
    + "</td>\n                    <td><span class=\"hotkey\"><kbd>Shift</kbd> + <kbd>P</kbd></span></td>\n                </tr>\n                <tr>\n                    <td class=\"definition\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Zoom to message in conversation context",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":155,"column":43},"end":{"line":155,"column":91}}}))
    + "</td>\n                    <td><span class=\"hotkey\"><kbd>Z</kbd></span></td>\n                </tr>\n                <tr>\n                    <td class=\"definition\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Go to next unread topic",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":159,"column":43},"end":{"line":159,"column":75}}}))
    + "</td>\n                    <td><span class=\"hotkey\"><kbd>N</kbd></span></td>\n                </tr>\n                <tr>\n                    <td class=\"definition\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Go to next unread followed topic",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":163,"column":43},"end":{"line":163,"column":84}}}))
    + "</td>\n                    <td><span class=\"hotkey\"><kbd>Shift</kbd> + <kbd>N</kbd></span></td>\n                </tr>\n                <tr>\n                    <td class=\"definition\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Go to next unread direct message",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":167,"column":43},"end":{"line":167,"column":84}}}))
    + "</td>\n                    <td><span class=\"hotkey\"><kbd>P</kbd></span></td>\n                </tr>\n                <tr>\n                    <td class=\"definition\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Cycle between channel views",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":171,"column":43},"end":{"line":171,"column":79}}}))
    + "</td>\n                    <td><span class=\"hotkey\"><kbd>Shift</kbd> + <kbd>A</kbd> or <kbd>Shift</kbd> + <kbd>D</kbd></span></td>\n                </tr>\n                <tr>\n                    <td class=\"definition\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Go to inbox",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":175,"column":43},"end":{"line":175,"column":63}}}))
    + "</td>\n                    <td><span class=\"hotkey\"><kbd>Shift</kbd> + <kbd>I</kbd></span></td>\n                </tr>\n                <tr>\n                    <td class=\"definition\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Go to recent conversations",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":179,"column":43},"end":{"line":179,"column":78}}}))
    + "</td>\n                    <td><span class=\"hotkey\"><kbd>T</kbd></span></td>\n                </tr>\n                <tr>\n                    <td class=\"definition\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Go to combined feed",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":183,"column":43},"end":{"line":183,"column":71}}}))
    + "</td>\n                    <td><span class=\"hotkey\"><kbd>A</kbd></span></td>\n                </tr>\n                <tr>\n                    <td class=\"definition\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Go to starred messages",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":187,"column":43},"end":{"line":187,"column":74}}}))
    + "</td>\n                    <td><span class=\"hotkey\"><kbd>*</kbd></span></td>\n                </tr>\n                <tr>\n                    <td class=\"definition\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Go to the conversation you are composing to",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":191,"column":43},"end":{"line":191,"column":95}}}))
    + "</td>\n                    <td><span class=\"hotkey\"><kbd>Ctrl</kbd> + <kbd>.</kbd></span></td>\n                </tr>\n            </table>\n        </div>\n        <div>\n            <table class=\"hotkeys_table table table-striped table-bordered\">\n                <thead>\n                    <tr>\n                        <th colspan=\"2\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Composing messages",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":200,"column":40},"end":{"line":200,"column":67}}}))
    + "</th>\n                    </tr>\n                </thead>\n                <tr>\n                    <td class=\"definition\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"New channel message",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":204,"column":43},"end":{"line":204,"column":71}}}))
    + "</td>\n                    <td><span class=\"hotkey\"><kbd>C</kbd></span></td>\n                </tr>\n                <tr>\n                    <td class=\"definition\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"New direct message",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":208,"column":43},"end":{"line":208,"column":70}}}))
    + "</td>\n                    <td><span class=\"hotkey\"><kbd>X</kbd></span></td>\n                </tr>\n                <tr>\n                    <td class=\"definition\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Reply to message",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":212,"column":43},"end":{"line":212,"column":68}}}))
    + "</td>\n                    <td><span class=\"hotkey\"><kbd>Enter</kbd> or <kbd>R</kbd></span></td>\n                </tr>\n                <tr>\n                    <td class=\"definition\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Quote message",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":216,"column":43},"end":{"line":216,"column":65}}}))
    + "</td>\n                    <td><span class=\"hotkey\"><kbd>></kbd></span></td>\n                </tr>\n                <tr>\n                    <td class=\"definition\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Forward message",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":220,"column":43},"end":{"line":220,"column":67}}}))
    + "</td>\n                    <td><span class=\"hotkey\"><kbd>&lt;</kbd></span></td> <!-- &lt; is the HTML entity for < -->\n                </tr>\n                <tr>\n                    <td class=\"definition\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Reply directly to sender",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":224,"column":43},"end":{"line":224,"column":76}}}))
    + "</td>\n                    <td><span class=\"hotkey\"><kbd>Shift</kbd> + <kbd>R</kbd></span></td>\n                </tr>\n                <tr>\n                    <td class=\"definition\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Reply @-mentioning sender",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":228,"column":43},"end":{"line":228,"column":77}}}))
    + "</td>\n                    <td><span class=\"hotkey\"><kbd>@</kbd></span></td>\n                </tr>\n                <tr>\n                    <td class=\"definition\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Send message",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":232,"column":43},"end":{"line":232,"column":64}}}))
    + "</td>\n                    <td><span class=\"hotkey\"><kbd>Tab</kbd> then <kbd>Enter</kbd> or <kbd>Ctrl</kbd> + <kbd>Enter</kbd></span></td>\n                </tr>\n                <tr>\n                    <td class=\"definition\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Insert new line",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":236,"column":43},"end":{"line":236,"column":67}}}))
    + "</td>\n                    <td><span class=\"hotkey\"><kbd>Shift</kbd> + <kbd>Enter</kbd></span></td>\n                </tr>\n                <tr>\n                    <td class=\"definition\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Toggle preview mode",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":240,"column":43},"end":{"line":240,"column":71}}}))
    + "</td>\n                    <td><span class=\"hotkey\"><kbd>Alt</kbd> + <kbd>P</kbd></span></td>\n                </tr>\n                <tr>\n                    <td class=\"definition\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Cancel compose and save draft",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":244,"column":43},"end":{"line":244,"column":81}}}))
    + "</td>\n                    <td><span class=\"hotkey\"><kbd>Esc</kbd> or <kbd data-mac-key=\"Ctrl\">Ctrl</kbd> + <kbd>[</kbd></span></td>\n                </tr>\n            </table>\n        </div>\n        <div>\n            <table class=\"hotkeys_table table table-striped table-bordered\">\n                <thead>\n                    <tr>\n                        <th colspan=\"2\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Message actions",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":253,"column":40},"end":{"line":253,"column":64}}}))
    + "</th>\n                    </tr>\n                </thead>\n                <tr>\n                    <td class=\"definition\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Edit your last message",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":257,"column":43},"end":{"line":257,"column":74}}}))
    + "</td>\n                    <td><span class=\"hotkey\"><kbd class=\"arrow-key\">←</kbd></span></td>\n                </tr>\n                <tr id=\"edit-message-hotkey-help\">\n                    <td class=\"definition\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Edit selected message or view source",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":261,"column":43},"end":{"line":261,"column":88}}}))
    + "</td>\n                    <td><span class=\"hotkey\"><kbd>E</kbd></span></td>\n                </tr>\n                <tr>\n                    <td class=\"definition\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Show message sender's user card",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":265,"column":43},"end":{"line":265,"column":85}}}))
    + "</td>\n                    <td><span class=\"hotkey\"><kbd>U</kbd></span></td>\n                </tr>\n                <tr>\n                    <td class=\"definition\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"View read receipts",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":269,"column":43},"end":{"line":269,"column":70}}}))
    + "</td>\n                    <td><span class=\"hotkey\"><kbd>Shift</kbd> + <kbd>V</kbd></span></td>\n                </tr>\n                <tr>\n                    <td class=\"definition\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Show images in thread",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":273,"column":43},"end":{"line":273,"column":73}}}))
    + "</td>\n                    <td><span class=\"hotkey\"><kbd>V</kbd></span></td>\n                </tr>\n                <tr id=\"move-message-hotkey-help\">\n                    <td class=\"definition\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Move messages or topic",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":277,"column":43},"end":{"line":277,"column":74}}}))
    + "</td>\n                    <td><span class=\"hotkey\"><kbd>M</kbd></span></td>\n                </tr>\n                <tr>\n                    <td class=\"definition\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"View edit and move history",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":281,"column":43},"end":{"line":281,"column":78}}}))
    + "</td>\n                    <td><span class=\"hotkey\"><kbd>Shift</kbd> + <kbd>H</kbd></span></td>\n                </tr>\n                <tr>\n                    <td class=\"definition\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Star selected message",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":285,"column":43},"end":{"line":285,"column":73}}}))
    + "</td>\n                    <td><span class=\"hotkey\"><kbd>Ctrl</kbd> + <kbd>S</kbd></span></td>\n                </tr>\n                <tr>\n                    <td class=\"definition\">\n                        "
    + alias2(lookupProperty(helpers,"t").call(alias1,"React to selected message with",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":290,"column":24},"end":{"line":290,"column":63}}}))
    + "\n                        <img alt=\":thumbs_up:\"\n                          class=\"emoji\"\n                          src=\"" + require("../../static/generated/emoji/images/emoji/unicode/1f44d.png") + "\"\n                          title=\":thumbs_up:\"/>\n                    </td>\n                    <td><span class=\"hotkey\"><kbd>+</kbd></span></td>\n                </tr>\n                <tr>\n                    <td class=\"definition\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Toggle first emoji reaction on selected message",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":299,"column":43},"end":{"line":299,"column":99}}}))
    + "</td>\n                    <td><span class=\"hotkey\"><kbd>=</kbd></span></td>\n                </tr>\n                <tr>\n                    <td class=\"definition\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Mark as unread from selected message",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":303,"column":43},"end":{"line":303,"column":88}}}))
    + "</td>\n                    <td><span class=\"hotkey\"><kbd>Shift</kbd> + <kbd>U</kbd></span></td>\n                </tr>\n                <tr>\n                    <td class=\"definition\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Collapse/show selected message",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":307,"column":43},"end":{"line":307,"column":82}}}))
    + "</td>\n                    <td><span class=\"hotkey\"><kbd>-</kbd></span></td>\n                </tr>\n                <tr>\n                    <td class=\"definition\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Toggle topic mute",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":311,"column":43},"end":{"line":311,"column":69}}}))
    + "</td>\n                    <td><span class=\"hotkey\"><kbd>Shift</kbd> + <kbd>M</kbd></span></td>\n                </tr>\n            </table>\n        </div>\n        <div>\n            <table class=\"hotkeys_table table table-striped table-bordered\">\n                <thead>\n                    <tr>\n                        <th colspan=\"2\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Recent conversations",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":320,"column":40},"end":{"line":320,"column":69}}}))
    + "</th>\n                    </tr>\n                </thead>\n                <tr>\n                    <td class=\"definition\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"View recent conversations",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":324,"column":43},"end":{"line":324,"column":77}}}))
    + "</td>\n                    <td><span class=\"hotkey\"><kbd>T</kbd></span></td>\n                </tr>\n                <tr>\n                    <td class=\"definition\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Filter topics",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":328,"column":43},"end":{"line":328,"column":65}}}))
    + "</td>\n                    <td><span class=\"hotkey\"><kbd>T</kbd></span></td>\n                </tr>\n            </table>\n        </div>\n        <div>\n            <table class=\"hotkeys_table table table-striped table-bordered\">\n                <thead>\n                    <tr>\n                        <th colspan=\"2\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Drafts",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":337,"column":40},"end":{"line":337,"column":55}}}))
    + "</th>\n                    </tr>\n                </thead>\n                <tr>\n                    <td class=\"definition\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"View drafts",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":341,"column":43},"end":{"line":341,"column":63}}}))
    + "</td>\n                    <td><span class=\"hotkey\"><kbd>D</kbd></span></td>\n                </tr>\n                <tr>\n                    <td class=\"definition\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Edit selected draft",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":345,"column":43},"end":{"line":345,"column":71}}}))
    + "</td>\n                    <td><span class=\"hotkey\"><kbd>Enter</kbd></span></td>\n                </tr>\n                <tr>\n                    <td class=\"definition\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Delete selected draft",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":349,"column":43},"end":{"line":349,"column":73}}}))
    + "</td>\n                    <td><span class=\"hotkey\"><kbd>Backspace</kbd></span></td>\n                </tr>\n            </table>\n        </div>\n        <div>\n            <table class=\"hotkeys_table table table-striped table-bordered\">\n                <thead>\n                    <tr>\n                        <th colspan=\"2\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Menus",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":358,"column":40},"end":{"line":358,"column":54}}}))
    + "</th>\n                    </tr>\n                </thead>\n                <tr>\n                    <td class=\"definition\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Toggle the gear menu",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":362,"column":43},"end":{"line":362,"column":72}}}))
    + "</td>\n                    <td><span class=\"hotkey\"><kbd>G</kbd></span></td>\n                </tr>\n                <tr>\n                    <td class=\"definition\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Open personal menu",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":366,"column":43},"end":{"line":366,"column":70}}}))
    + "</td>\n                    <td><span class=\"hotkey\"><kbd>G</kbd><kbd class=\"arrow-key\">→</kbd></span></td>\n                </tr>\n                <tr>\n                    <td class=\"definition\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Open help menu",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":370,"column":43},"end":{"line":370,"column":66}}}))
    + "</td>\n                    <td><span class=\"hotkey\"><kbd>G</kbd><kbd class=\"arrow-key\">←</kbd></span></td>\n                </tr>\n                <tr>\n                    <td class=\"definition\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Open message menu",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":374,"column":43},"end":{"line":374,"column":69}}}))
    + "</td>\n                    <td><span class=\"hotkey\"><kbd>I</kbd></span></td>\n                </tr>\n                <tr>\n                    <td class=\"definition\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Open reactions menu",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":378,"column":43},"end":{"line":378,"column":71}}}))
    + "</td>\n                    <td><span class=\"hotkey\"><kbd>:</kbd></span></td>\n                </tr>\n                <tr>\n                    <td class=\"definition\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Show keyboard shortcuts",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":382,"column":43},"end":{"line":382,"column":75}}}))
    + "</td>\n                    <td><span class=\"hotkey\"><kbd>?</kbd></span></td>\n                </tr>\n            </table>\n        </div>\n        <div>\n            <table class=\"hotkeys_table table table-striped table-bordered\">\n                <thead>\n                    <tr>\n                        <th colspan=\"2\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Channel settings",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":391,"column":40},"end":{"line":391,"column":65}}}))
    + "</th>\n                    </tr>\n                </thead>\n                <tr>\n                    <td class=\"definition\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Scroll through channels",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":395,"column":43},"end":{"line":395,"column":75}}}))
    + "</td>\n                    <td><span class=\"hotkey\"><kbd class=\"arrow-key\">↑</kbd> or <kbd class=\"arrow-key\">↓</kbd></span></td>\n                </tr>\n                <tr>\n                    <td class=\"definition\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Switch between tabs",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":399,"column":43},"end":{"line":399,"column":71}}}))
    + "</td>\n                    <td><span class=\"hotkey\"><kbd class=\"arrow-key\">←</kbd> or <kbd class=\"arrow-key\">→</kbd></span></td>\n                </tr>\n                <tr>\n                    <td class=\"definition\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"View channel messages",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":403,"column":43},"end":{"line":403,"column":73}}}))
    + "</td>\n                    <td><span class=\"hotkey\"><kbd>Shift</kbd> + <kbd>V</kbd></span></td>\n                </tr>\n                <tr>\n                    <td class=\"definition\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Subscribe to/unsubscribe from selected channel",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":407,"column":43},"end":{"line":407,"column":98}}}))
    + "</td>\n                    <td><span class=\"hotkey\"><kbd>Shift</kbd> + <kbd>S</kbd></span></td>\n                </tr>\n                <tr>\n                    <td class=\"definition\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Create new channel",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":411,"column":43},"end":{"line":411,"column":70}}}))
    + "</td>\n                    <td><span class=\"hotkey\"><kbd>N</kbd></span></td>\n                </tr>\n            </table>\n        </div>\n        <hr />\n        <a href=\"/help/keyboard-shortcuts\" target=\"_blank\" rel=\"noopener noreferrer\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Detailed keyboard shortcuts documentation",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":417,"column":85},"end":{"line":417,"column":135}}}))
    + "</a>\n    </div>\n</div>\n";
},"useData":true});