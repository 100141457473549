var Handlebars = require("../../../../node_modules/.pnpm/handlebars@4.7.8_patch_hash=c5t5wnxp6mvabdrpn7wm4zt2zu/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return " tabindex=0 ";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"compose-control-buttons-container preview_mode_disabled\">\n    <a role=\"button\" data-format-type=\"numbered\" class=\"compose_control_button zulip-icon zulip-icon-ordered-list formatting_button\" aria-label=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"Numbered list",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":2,"column":145},"end":{"line":2,"column":167}}}))
    + "\" "
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,lookupProperty(depth0,"preview_mode_on"),{"name":"unless","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":2,"column":169},"end":{"line":2,"column":219}}})) != null ? stack1 : "")
    + " data-tippy-content=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"Numbered list",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":2,"column":240},"end":{"line":2,"column":262}}}))
    + "\"></a>\n    <a role=\"button\" data-format-type=\"bulleted\" class=\"compose_control_button zulip-icon zulip-icon-unordered-list formatting_button\" aria-label=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"Bulleted list",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":3,"column":147},"end":{"line":3,"column":169}}}))
    + "\" "
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,lookupProperty(depth0,"preview_mode_on"),{"name":"unless","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":3,"column":171},"end":{"line":3,"column":221}}})) != null ? stack1 : "")
    + " data-tippy-content=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"Bulleted list",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":3,"column":242},"end":{"line":3,"column":264}}}))
    + "\"></a>\n    <div class=\"divider\"></div>\n    <a role=\"button\" data-format-type=\"quote\" class=\"compose_control_button zulip-icon zulip-icon-quote formatting_button\" aria-label=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"Quote",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":5,"column":135},"end":{"line":5,"column":149}}}))
    + "\" "
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,lookupProperty(depth0,"preview_mode_on"),{"name":"unless","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":5,"column":151},"end":{"line":5,"column":201}}})) != null ? stack1 : "")
    + " data-tippy-content=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"Quote",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":5,"column":222},"end":{"line":5,"column":236}}}))
    + "\"></a>\n    <a role=\"button\" data-format-type=\"spoiler\" class=\"compose_control_button zulip-icon zulip-icon-spoiler formatting_button\" aria-label=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"Spoiler",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":6,"column":139},"end":{"line":6,"column":155}}}))
    + "\" "
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,lookupProperty(depth0,"preview_mode_on"),{"name":"unless","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":6,"column":157},"end":{"line":6,"column":207}}})) != null ? stack1 : "")
    + " data-tippy-content=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"Spoiler",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":6,"column":228},"end":{"line":6,"column":244}}}))
    + "\"></a>\n    <a role=\"button\" data-format-type=\"code\" class=\"compose_control_button zulip-icon zulip-icon-code formatting_button\" aria-label=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"Code",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":7,"column":133},"end":{"line":7,"column":146}}}))
    + "\" "
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,lookupProperty(depth0,"preview_mode_on"),{"name":"unless","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":7,"column":148},"end":{"line":7,"column":198}}})) != null ? stack1 : "")
    + " data-tippy-content=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"Code",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":7,"column":219},"end":{"line":7,"column":232}}}))
    + "\"></a>\n    <a role=\"button\" data-format-type=\"latex\" class=\"compose_control_button zulip-icon zulip-icon-math formatting_button\" aria-label=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"Math (LaTeX)",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":8,"column":134},"end":{"line":8,"column":155}}}))
    + "\" "
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,lookupProperty(depth0,"preview_mode_on"),{"name":"unless","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":8,"column":157},"end":{"line":8,"column":207}}})) != null ? stack1 : "")
    + " data-tippy-content=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"Math (LaTeX)",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":8,"column":228},"end":{"line":8,"column":249}}}))
    + "\"></a>\n    <div class=\"divider\"></div>\n</div>\n<a role=\"button\" class=\"compose_control_button compose_help_button zulip-icon zulip-icon-question\" tabindex=0 data-tippy-content=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"Message formatting",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":11,"column":130},"end":{"line":11,"column":157}}}))
    + "\" data-overlay-trigger=\"message-formatting\"></a>\n";
},"useData":true});