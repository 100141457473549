var Handlebars = require("../../node_modules/.pnpm/handlebars@4.7.8_patch_hash=c5t5wnxp6mvabdrpn7wm4zt2zu/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return " out_of_home_view";
},"3":function(container,depth0,helpers,partials,data) {
    return "hide_unread_counts";
},"5":function(container,depth0,helpers,partials,data) {
    var alias1=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <div class=\"channel-new-topic-button tippy-zulip-tooltip hidden-for-spectators\" data-tippy-content=\""
    + alias1(lookupProperty(helpers,"t").call(depth0 != null ? depth0 : (container.nullContext || {}),"New topic",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":15,"column":116},"end":{"line":15,"column":133}}}))
    + "\" data-stream-id=\""
    + alias1(container.lambda(container.strict(depth0, "id", {"start":{"line":15,"column":153},"end":{"line":15,"column":155}} ), depth0))
    + "\">\n                    <i class=\"channel-new-topic-icon zulip-icon zulip-icon-square-plus\" aria-hidden=\"true\"></i>\n                </div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.strict, alias3=container.lambda, alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "\n<li class=\"narrow-filter"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"is_muted"),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":3,"column":24},"end":{"line":3,"column":64}}})) != null ? stack1 : "")
    + "\" data-stream-id=\""
    + alias4(alias3(alias2(depth0, "id", {"start":{"line":3,"column":84},"end":{"line":3,"column":86}} ), depth0))
    + "\">\n    <div class=\"bottom_left_row\">\n        <div class=\"subscription_block selectable_sidebar_block "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"hide_unread_count"),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":5,"column":64},"end":{"line":5,"column":114}}})) != null ? stack1 : "")
    + "\">\n\n            <span class=\"stream-privacy-original-color-"
    + alias4(alias3(alias2(depth0, "id", {"start":{"line":7,"column":57},"end":{"line":7,"column":59}} ), depth0))
    + " stream-privacy filter-icon\" style=\"color: "
    + alias4(alias3(alias2(depth0, "color", {"start":{"line":7,"column":106},"end":{"line":7,"column":111}} ), depth0))
    + "\">\n                "
    + ((stack1 = container.invokePartial(require("./stream_privacy.hbs"),depth0,{"name":"stream_privacy","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "            </span>\n\n            <a href=\""
    + alias4(alias3(alias2(depth0, "url", {"start":{"line":11,"column":23},"end":{"line":11,"column":26}} ), depth0))
    + "\" class=\"stream-name\">"
    + alias4(alias3(alias2(depth0, "name", {"start":{"line":11,"column":52},"end":{"line":11,"column":56}} ), depth0))
    + "</a>\n\n            <div class=\"left-sidebar-controls\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"can_post_messages"),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":14,"column":16},"end":{"line":18,"column":23}}})) != null ? stack1 : "")
    + "            </div>\n\n            <div class=\"stream-markers-and-unreads\">\n                <span class=\"unread_mention_info\"></span>\n                <span class=\"unread_count normal-count\"></span>\n                <span class=\"masked_unread_count\">\n                    <i class=\"zulip-icon zulip-icon-masked-unread\"></i>\n                </span>\n            </div>\n\n            <span class=\"sidebar-menu-icon stream-sidebar-menu-icon\"><i class=\"zulip-icon zulip-icon-more-vertical\" aria-hidden=\"true\"></i></span>\n        </div>\n    </div>\n</li>\n";
},"usePartial":true,"useData":true});