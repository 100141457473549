var Handlebars = require("../../../node_modules/.pnpm/handlebars@4.7.8_patch_hash=c5t5wnxp6mvabdrpn7wm4zt2zu/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "settings-subsection-parent";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <div class=\"subsection-header\">\n            <h3 class=\"stream_setting_subsection_title\">"
    + container.escapeExpression(lookupProperty(helpers,"t").call(depth0 != null ? depth0 : (container.nullContext || {}),"Channel permissions",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":4,"column":56},"end":{"line":4,"column":84}}}))
    + "\n            </h3>\n            "
    + ((stack1 = container.invokePartial(require("../settings/settings_save_discard_widget.hbs"),undefined,{"name":"../settings/settings_save_discard_widget","hash":{"section_name":"stream-permissions"},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "        </div>\n        <div class=\"stream-permissions-warning-banner\"></div>\n";
},"5":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=container.strict, alias2=container.lambda, alias3=container.escapeExpression, alias4=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <div class=\"settings-radio-input-parent\">\n                    <label class=\"radio\">\n                        <input type=\"radio\" name=\"privacy\" value=\""
    + alias3(alias2(alias1(depth0, "code", {"start":{"line":21,"column":69},"end":{"line":21,"column":78}} ), depth0))
    + "\" "
    + ((stack1 = lookupProperty(helpers,"if").call(alias4,lookupProperty(helpers,"eq").call(alias4,lookupProperty(depth0,"code"),lookupProperty(depths[1],"stream_privacy_policy"),{"name":"eq","hash":{},"data":data,"loc":{"start":{"line":21,"column":89},"end":{"line":21,"column":128}}}),{"name":"if","hash":{},"fn":container.program(6, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":21,"column":83},"end":{"line":21,"column":145}}})) != null ? stack1 : "")
    + " />\n                        <b>"
    + alias3(alias2(alias1(depth0, "name", {"start":{"line":22,"column":30},"end":{"line":22,"column":39}} ), depth0))
    + ":</b> "
    + alias3(alias2(alias1(depth0, "description", {"start":{"line":22,"column":51},"end":{"line":22,"column":67}} ), depth0))
    + "\n                    </label>\n                </div>\n";
},"6":function(container,depth0,helpers,partials,data) {
    return "checked";
},"8":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "        <div id=\"announce-new-stream\">\n            "
    + ((stack1 = container.invokePartial(require("./announce_stream_checkbox.hbs"),depth0,{"name":"announce_stream_checkbox","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "        </div>\n";
},"10":function(container,depth0,helpers,partials,data) {
    return "subsection-header";
},"12":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "            "
    + ((stack1 = container.invokePartial(require("../settings/settings_save_discard_widget.hbs"),undefined,{"name":"../settings/settings_save_discard_widget","hash":{"section_name":"stream-permissions"},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"14":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.strict, alias2=container.lambda, alias3=container.escapeExpression, alias4=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <div>\n                <div class=\"input-group inline-block message-retention-setting-group time-limit-setting\">\n                    <label class=\"dropdown-title settings-field-label\" for=\""
    + alias3(alias2(alias1(depth0, "prefix", {"start":{"line":78,"column":78},"end":{"line":78,"column":84}} ), depth0))
    + "message_retention_days\">"
    + alias3(lookupProperty(helpers,"t").call(alias4,"Message retention period",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":78,"column":110},"end":{"line":78,"column":143}}}))
    + "\n                        "
    + ((stack1 = container.invokePartial(require("../help_link_widget.hbs"),undefined,{"name":"../help_link_widget","hash":{"link":"/help/message-retention-policy"},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "                    </label>\n\n                    "
    + ((stack1 = container.invokePartial(require("../settings/upgrade_tip_widget.hbs"),depth0,{"name":"../settings/upgrade_tip_widget","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "\n                    <select name=\"stream_message_retention_setting\"\n                      class=\"stream_message_retention_setting prop-element settings_select bootstrap-focus-style\"\n                      id=\""
    + alias3(alias2(alias1(depth0, "prefix", {"start":{"line":86,"column":28},"end":{"line":86,"column":34}} ), depth0))
    + "message_retention_days\"\n                      data-setting-widget-type=\"message-retention-setting\">\n                        <option value=\"realm_default\">"
    + alias3(lookupProperty(helpers,"t").call(alias4,"Use organization level settings {org_level_message_retention_setting}",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":88,"column":54},"end":{"line":88,"column":132}}}))
    + "</option>\n                        <option value=\"unlimited\">"
    + alias3(lookupProperty(helpers,"t").call(alias4,"Retain forever",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":89,"column":50},"end":{"line":89,"column":73}}}))
    + "</option>\n                        <option value=\"custom_period\">"
    + alias3(lookupProperty(helpers,"t").call(alias4,"Custom",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":90,"column":54},"end":{"line":90,"column":69}}}))
    + "</option>\n                    </select>\n\n                    <div class=\"dependent-settings-block stream-message-retention-days-input\">\n                        <label class=\"inline-block\" for=\""
    + alias3(alias2(alias1(depth0, "prefix", {"start":{"line":94,"column":59},"end":{"line":94,"column":65}} ), depth0))
    + "stream_message_retention_custom_input\">\n                            "
    + alias3(lookupProperty(helpers,"t").call(alias4,"Retention period (days)",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":95,"column":28},"end":{"line":95,"column":60}}}))
    + ":\n                        </label>\n                        <input type=\"text\" autocomplete=\"off\"\n                          name=\"stream-message-retention-days\"\n                          class=\"stream-message-retention-days message-retention-setting-custom-input time-limit-custom-input\"\n                          id=\""
    + alias3(alias2(alias1(depth0, "prefix", {"start":{"line":100,"column":32},"end":{"line":100,"column":38}} ), depth0))
    + "stream_message_retention_custom_input\" />\n                    </div>\n                </div>\n            </div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div id=\"stream_permission_settings\" class=\"stream-permissions "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"is_stream_edit"),{"name":"if","hash":{},"fn":container.program(1, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":63},"end":{"line":1,"column":118}}})) != null ? stack1 : "")
    + "\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"is_stream_edit"),{"name":"if","hash":{},"fn":container.program(3, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":2,"column":4},"end":{"line":9,"column":11}}})) != null ? stack1 : "")
    + "\n    <div class=\"input-group stream-privacy-values\">\n        <div class=\"alert stream-privacy-status\"></div>\n        <label>"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Who can access this channel",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":13,"column":15},"end":{"line":13,"column":50}}}))
    + "\n            "
    + ((stack1 = container.invokePartial(require("../help_link_widget.hbs"),undefined,{"name":"../help_link_widget","hash":{"link":"/help/channel-permissions"},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "        </label>\n\n        <div class=\"stream-privacy_choices prop-element\" id=\""
    + alias2(container.lambda(container.strict(depth0, "prefix", {"start":{"line":17,"column":63},"end":{"line":17,"column":69}} ), depth0))
    + "stream_privacy\" data-setting-widget-type=\"radio-group\" data-setting-choice-type=\"string\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,lookupProperty(depth0,"stream_privacy_policy_values"),{"name":"each","hash":{},"fn":container.program(5, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":18,"column":12},"end":{"line":25,"column":21}}})) != null ? stack1 : "")
    + "        </div>\n    </div>\n\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"ask_to_announce_stream"),{"name":"if","hash":{},"fn":container.program(8, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":29,"column":4},"end":{"line":33,"column":11}}})) != null ? stack1 : "")
    + "\n    <div class=\"default-stream\">\n        "
    + ((stack1 = container.invokePartial(require("../settings/settings_checkbox.hbs"),undefined,{"name":"../settings/settings_checkbox","hash":{"help_link":"/help/set-default-channels-for-new-users","label":lookupProperty(helpers,"t").call(alias1,"Default channel for new users",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":40,"column":16},"end":{"line":40,"column":51}}}),"is_checked":lookupProperty(depth0,"check_default_stream"),"setting_name":"is_default_stream","prefix":lookupProperty(depth0,"prefix")},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "    </div>\n</div>\n\n<div id=\"stream-advanced-configurations\" class=\"advanced-configurations-container stream-permissions "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"is_stream_edit"),{"name":"if","hash":{},"fn":container.program(1, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":46,"column":101},"end":{"line":46,"column":156}}})) != null ? stack1 : "")
    + "\">\n    <div class=\"advance-config-title-container "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"is_stream_edit"),{"name":"if","hash":{},"fn":container.program(10, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":47,"column":47},"end":{"line":47,"column":93}}})) != null ? stack1 : "")
    + "\">\n        <i class=\"fa fa-sm fa-caret-right toggle-advanced-configurations-icon\" aria-hidden=\"true\"></i>\n        <h4 class=\"stream_setting_subsection_title\"><span>"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Advanced configurations",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":49,"column":58},"end":{"line":49,"column":90}}}))
    + "</span></h4>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"is_stream_edit"),{"name":"if","hash":{},"fn":container.program(12, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":50,"column":8},"end":{"line":52,"column":15}}})) != null ? stack1 : "")
    + "    </div>\n    <div class=\"advanced-configurations-collapase-view hide\">\n\n        "
    + ((stack1 = container.invokePartial(require("../settings/group_setting_value_pill_input.hbs"),undefined,{"name":"../settings/group_setting_value_pill_input","hash":{"help_link":"/help/stream-sending-policy","prefix":lookupProperty(depth0,"prefix"),"label":lookupProperty(helpers,"t").call(alias1,"Who can post to this channel",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":58,"column":16},"end":{"line":58,"column":50}}}),"setting_name":"can_send_message_group"},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "\n        "
    + ((stack1 = container.invokePartial(require("../settings/group_setting_value_pill_input.hbs"),undefined,{"name":"../settings/group_setting_value_pill_input","hash":{"prefix":lookupProperty(depth0,"prefix"),"label_parens_text":lookupProperty(helpers,"t").call(alias1,"in addition to organization administrators",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":65,"column":28},"end":{"line":65,"column":76}}}),"label":lookupProperty(helpers,"t").call(alias1,"Who can administer this channel",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":64,"column":16},"end":{"line":64,"column":53}}}),"setting_name":"can_administer_channel_group"},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "\n        "
    + ((stack1 = container.invokePartial(require("../settings/group_setting_value_pill_input.hbs"),undefined,{"name":"../settings/group_setting_value_pill_input","hash":{"prefix":lookupProperty(depth0,"prefix"),"label_parens_text":lookupProperty(helpers,"t").call(alias1,"in addition to organization administrators",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":71,"column":28},"end":{"line":71,"column":76}}}),"label":lookupProperty(helpers,"t").call(alias1,"Who can unsubscribe others from this channel",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":70,"column":16},"end":{"line":70,"column":66}}}),"setting_name":"can_remove_subscribers_group"},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "\n\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(helpers,"or").call(alias1,lookupProperty(depth0,"is_owner"),lookupProperty(depth0,"is_stream_edit"),{"name":"or","hash":{},"data":data,"loc":{"start":{"line":75,"column":14},"end":{"line":75,"column":42}}}),{"name":"if","hash":{},"fn":container.program(14, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":75,"column":8},"end":{"line":104,"column":15}}})) != null ? stack1 : "")
    + "    </div>\n</div>\n";
},"usePartial":true,"useData":true,"useDepths":true});